import React, { useState, useEffect } from "react";
import * as helper                    from "../global/Helper";
import moment                         from "moment";
import blog from "../../assets/images/blog.png"
import "./TagTable.css";

const  BlogTable = (props) => {

  const {
    EditBlogHandler,
    toggleBlogStatus
  } = props;

  const [AllBlogs, setAllBlog] = useState(props.AllBlogs);
  const [showRemoveSearch, setshowRemoveSearch] = useState(false);

  const searchBlogHandler =({target})=>{
    if(target.value === ""){
      setshowRemoveSearch(false);
      return setAllBlog(props.AllBlogs);
    }

    setshowRemoveSearch(true);
    const matchedBlogs = props.AllBlogs.filter(b=>{
      let matchString =`${b.Title} ${b.Alias} ${b.Name} ${moment(b.Date).format("DD/MM/YYYY hh:mm A")} ${b.Description} ${b.MetaTitle} ${b.MetaKeyword} ${b.MetaDescription}`;
      let matchFound = 0;

      const searchArr = target.value.split(' ');
      searchArr.forEach(term => {
        matchFound += matchString.toLowerCase().includes(term.toLowerCase())?1:0;
      });

      return matchFound === searchArr.length;
    })
    setAllBlog(matchedBlogs);
  }

  const clearSearchHandler = () =>{ 
    $("#generalSearch").val("");
    setshowRemoveSearch(false);
    return setAllBlog(props.AllBlogs);
  }


  useEffect(() => {
    helper.SetScrollHeight();
  });

  return (
    <div className="kt-form kt-form--label-right kt-margin-b-10">
      <div className="row align-items-center stickyFilter">
        <div className="col-xl-12 order-2 order-xl-1">
          <div className="row align-items-center">
            <div className="col-md-4 kt-margin-b-20-tablet-and-mobile margin-b0">
              <div className="kt-input-icon kt-input-icon--left">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Blogs..."
                  id="generalSearch"
                  onChange={searchBlogHandler}>
                </input>
                <span className="kt-input-icon__icon kt-input-icon__icon--left">
                  <span>
                    <i className="la la-search"></i>
                  </span>
                </span>
                {showRemoveSearch && (
                  <span className="kt-input-icon__icon kt-input-icon__icon--right">
                      <span onClick={clearSearchHandler}>
                          <i className="la la-close"></i>
                      </span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {AllBlogs.length > 0 ? (
        <div className="row">
          <div className="col-sm-12">
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
              <table className="kt-datatable__table">
                <thead
                  className="kt-datatable__head"
                  style={{ display: "block" }}>
                  <tr className="kt-datatable__row block-row">
                    <th className="kt-datatable__cell text-clip" width="60%">
                      <span>Blogs</span>
                    </th>
                    <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="20%">
                      <span>Date</span>
                    </th>
                    <th className="kt-datatable__cell--right kt-datatable__cell padding-l50" width="20%">
                      <span className="margin-r40">Action</span>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div
              className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable"
              onScroll={helper.setStickyHeader}>
              <table className="kt-datatable__table table-striped">
                <tbody
                  className="kt-datatable__body"
                  style={{ display: "block" }}>
                  {AllBlogs.map((b, i) => {
                    return (
                      <tr
                        key={i}
                        className="kt-datatable__row block-row ">
                        <td
                          width="60%"
                          title={b.Title}
                          className="kt-datatable__cell text-clip">
                          <div className="kt-user-card-v2">
                          <div className="kt-user-card-v2__pic">
                            <img className="blog" src={blog} alt={b.Title} />
                            </div>           
                            <div className="kt-user-card-v2__details text-clip">
                              <span className="kt-user-card-v2__name text-clip">
                                {b.Title}
                              </span>
                              {b.Name &&  (
                                <span className = "kt-user-card-v2__desc text clip" title={b.Name}>
                                  {b.Name}
                                </span>
                              )}
                            </div>
                          </div>
                        </td>
                        <td
                          className="kt-datatable__cell--center kt-datatable__cell text-clip"
                          width="20%"
                          title = {`${moment(b.Date).format("DD/MM/YYYY")} - ${moment(b.Date).format(" hh:mm A")} `}>
                          <span>{`${moment(b.Date).format("DD/MM/YYYY")} - ${moment(b.Date).format(" hh:mm A")} `}</span>
                        </td>
                        <td width="20%" 
                         className=" kt-datatable__cell lucid">
                          <button
                            type="button"
                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"                       
                            onClick={()=> EditBlogHandler(b)}>
                            <i className="flaticon2-edit"></i>
                          </button>
                          <span className="kt-switch kt-switch--icon padding-l10 padding-t5 ">
                            <label>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  b.Active === "Y" ? "checked" : ""
                                }
                                onChange={() => {
                                  b.Active = b.Active === "Y"?"N":"Y";
                                  toggleBlogStatus(b)
                                }}
                              />
                              <span></span>
                            </label>
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <span className="empty-table-message">No Blogs Found</span>
      )}
    </div>
  );
};

export default BlogTable;
