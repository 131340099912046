import React, { useState } from "react";
// import {Link} from "react-router-dom"
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/jquery-validation.init';
import * as helper from "../global/Helper"
import bannerLogo from "../../assets/images/banner-logo.png";
import "../../assets/styles/login.css";
import Swal from "sweetalert2"
import {loginAPI} from "../api/loginAPI";
import {setAuthState} from "../../assets/scripts/login-util";


const Login = () => {
  const [loginData,setLoginData] = useState({isremember: true})

  const loginDataHandler =(key,value)=>{
    setLoginData((d)=> {return {...d,[key]:value}});
  }


  const showAlert = (text, form, time = 3000) => {
    helper.ShowMsg(form, 'danger', text || 'Unknown error');
    setTimeout(() => {
      helper.RemoveMsg(form);
    }, time);
  };

  const submitHandler =async(e)=>{
    e.preventDefault();

    const btn = $('#kt_login_signin_submit');
    const form = $('#kt_login_signin_submit').closest('form');

    form.validate({
      rules:{
        username:{
          required: true,
        },
        password:{
          required: true,
        }
      }
    })

    if(!form.valid()){
      return;
    }

    helper.StartProcessing(btn);
    try {
      //Call login APi
      const params  = await loginAPI(loginData);
      //Set Auth State
      setAuthState(params,loginData.isremember);
      window.location.reload()
    } catch (err) {
      helper.StopProcessing(btn);
      Swal.fire({
        icon: "error",
        titleText: "Error!",
        text: err.message,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-brand",
      });
    }
  }

  return (
    <>
      <div
        className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3"
        id="kt_login"
      >
        <div
          className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
          style={{ backgroundColor: "#f7f8fa" }}
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
            <div className="kt-login__container">
              <div className="kt-login__logo">
                <img height="38" src={bannerLogo} alt="logo" />
              </div>
              <div className="kt-login__signin">
                <div className="kt-login__head">
                  <h3 className="kt-login__title">Admin Login</h3>
                </div>
                <form className="kt-form" action="">
                  <div className="input-group">
                    <input
                      onChange={({ target })=>loginDataHandler('username',target.value)}
                      value={loginData.username || ''}
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="Username"
                      name="username"
                      autoComplete="off"
                    />
                  </div>
                  <div className="input-group">
                    <input
                      onChange={({ target })=>loginDataHandler('password',target.value)}
                      value={loginData.password || ''}
                      className="form-control form-control-lg"
                      type="password"
                      placeholder="Password"
                      name="password"
                    />
                  </div>
                  <div className="margin-t20">	
                      <label className="kt-checkbox kt-checkbox--tick kt-checkbox--blue">
                        <input type="checkbox" 
                        name="rememberme"
                        defaultChecked={loginData.isremember ?"checked":""}
                        onChange={()=>loginDataHandler("isremember",!loginData.isremember)}
                         /> Remember Me 
                        <span></span>
                      </label>
                  </div>
                  <div className="kt-login__actions">
                    <button
                      type="button"
                      onClick={submitHandler}
                      id="kt_login_signin_submit"
                      className="btn btn-theme btn-elevate btn-block kt-login__btn-primary"
                    >
                    Login
                    </button>
                  </div>        
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
