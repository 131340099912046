import React       from "react";
import { getLogo } from "../global/Helper";


const BacklinkTable = (props) => {
  const { 
    authorbacklinks, 
    editBacklinkHandler,  
    deleteBacklinkHandler,
    updateBacklinkStatus,
    selectedAuthor
  } = props;


  const updateBacklinktoggle = ( b, i ) => {
    if(!selectedAuthor){
      updateBacklinkStatus(b.Active === "Y" ? "N" : "Y", i) 
    }else{
      let backlink = b ;
      backlink.Active = backlink.Active === "Y" ? "N" : "Y";
      updateBacklinkStatus(backlink);
    }
  }

  return (
    <React.Fragment>
      <div className="author-container">
        {authorbacklinks && authorbacklinks.length > 0 ? (
          <div
            className={`row ${!selectedAuthor && "margin-t20"} scrolTable`}>
            <div className="col-12">
              <table className="table table-striped">
                {!selectedAuthor &&
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th className="text-right"><span className="margin-r50">Action</span></th>
                    </tr>
                  </thead>
                }
                <tbody>
                  {authorbacklinks
                    .sort((a, b) => a.Index - b.Index)
                    .map((b, i) => (
                      <tr key={i}>
                        <td width="80%">
                          <div className="kt-user-card-v2">
                            <div className="kt-user-card-v2__pic">
                              <img
                                height="30px"
                                width="30px"
                                src={getLogo(b.Type)}
                                alt=""
                              />
                            </div>
                            <div className="kt-user-card-v2__details">
                              <span className="kt-user-card-v2__name">
                                <a
                                  href={b.Url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {b.Type}
                                </a>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td width="20%" className="text-right lucid">
                          <span className="pt-1">
                            <button
                              type="button"
                              className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"                       
                              onClick={()=> editBacklinkHandler({...b,mainIndex:i})}>
                              <i className="flaticon2-edit"></i>
                            </button>
                            {!selectedAuthor &&
                              <button
                                type="button"
                                onClick={() => deleteBacklinkHandler(i)}
                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                <i className="flaticon2-rubbish-bin-delete-button"></i>
                              </button>
                            }
                          </span>                        
                          <span className="kt-switch kt-switch--icon margin-r10">
                            <label>
                              <input
                                type="checkbox"
                                name=""
                                checked={b.Active ===  "Y"?'checked':''} 
                                onChange={()=>updateBacklinktoggle(b,i)}
                              />
                              <span></span>
                            </label>
                          </span> 
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <span className="empty-table-message">No backlinks</span>
        )}
      </div>
      
    </React.Fragment>
  );
};

export default BacklinkTable;
