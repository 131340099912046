import { axiosPost, axiosGet,axiosPatch } from "./axiosFunctions";
import {checkLoginToken}                  from "./common";

const addTestimonialUrl       ="/api/homepage/addTestimonial";
const addClientlUrl           ="/api/homepage/addClient";
const getContactUsUrl         ="/api/homepage/getContactUs";
const getTestimonialAllUrl    ="/api/homepage/getTestimonialAll";
const getClientAllUrl         ="/api/homepage/getClientAll";
const updateTestimonialUrl    ="/api/homepage/updateTestimonial";
const updateClientUrl         ="/api/homepage/updateClient";

//Add

//AddTestimonial
export const addTestimonial = async (testimonial) => {
  const token = checkLoginToken();
     if (!token) return;
     const response = await axiosPost(
      addTestimonialUrl,
      { testimonial },
      { headers: { auth_token: `bearer ${token}` } }
  )
  return response;
};

//AddClient
export const addClient = async (client) => {
  const token = checkLoginToken();
     if (!token) return;
     const response = await axiosPost(
      addClientlUrl,
      { client },
      { headers: { auth_token: `bearer ${token}` } }
  )
  return response;
};


//Get


//ContactUs
export const getContactUs = async () => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    getContactUsUrl,
    { headers: {auth_token: `bearer ${token}`} }
  );
  return response;
};

//Testimonial
export const getTestimonialAll = async () => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    getTestimonialAllUrl,
    { headers: {auth_token: `bearer ${token}`} }
  );
  return response;
};

//Client
export const getClientAll = async () => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    getClientAllUrl,
    { headers: {auth_token: `bearer ${token}`} }
  );
  return response;
};

//Update

//Testimonial
export const  updateTestimonial = async (testimonial) => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosPatch(
    updateTestimonialUrl,
    { testimonial },
    { headers: { auth_token: `bearer ${token}` } }
  )
  return response;
};

//Client
export const  updateClient = async (client) => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosPatch(
    updateClientUrl,
    { client },
    { headers: { auth_token: `bearer ${token}` } }
  )
  return response;
};

