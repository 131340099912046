import React, { useState, useEffect }                         from "react";
import { NavLink }                                            from "react-router-dom";
import $                                                      from "jquery";
import Loading                                                from "../global/Loading";
import TestimonialTable                                       from "./TestimonialsTable";
import * as helper                                            from "../global/Helper";
import Swal                                                   from "sweetalert2";
import imageLogo                                              from '../../assets/images/ic_image.png';
import ImageCropper                                           from "../global/ImageCropper";
import {uploadFile}                                           from  "../api/fileAPI";
import {addTestimonial, getTestimonialAll, updateTestimonial} from "../api/homepageAPI";
import "jquery-form";
import "jquery-validation";
import "../../assets/scripts/bootstrap-modal";
import "../../assets/scripts/jquery-validation.init";

export const AddTestimonials = (props) => {

  const { 
    show, 
    onDismissModal,
    selectedTestimonial,
    refetchTestimonials
  } = props;
  
  const [testimonial,       setTestimonialData]   = useState(selectedTestimonial? selectedTestimonial: { Active: "Y"});
  const [bannerImageBlob,   setBannerImageBlob]   = useState(null);
  const [showImageCropper,  setShowImageCropper]  = useState(false);

  //TestimonialDataHandler
  const TestimonialDataHandler = ( key, value ) => {
    setTestimonialData((data) => {
      return { ...data, [key]: value };
    });
  };
  
  const submitTestimonialHandler = async (e) => {
    e.preventDefault();
    var form = $("#add-member-form");
    form.validate().destroy();
    form.validate({
      rules: {
        Name: {
          required: true,
        },
        Content: {
            required: true,
        },
        Index: {
            required: true,
        },
      },
    });
    
    if (!form.valid()) {
      return;
    }
  
    //Submit TestimonialData
    try{
      
      let fileName ;
      if(bannerImageBlob) fileName = await uploadFile(bannerImageBlob,testimonial.Name.replace(/\s/g, "-").toLowerCase());

      if(fileName) testimonial.Image = fileName;

      if(selectedTestimonial){

        if(!bannerImageBlob && testimonial.Image) testimonial.Image = testimonial.Image.split("WebsiteImages/")[1];
        await updateTestimonial(testimonial);
        Swal.fire({
          icon                      : "success",
          titleText                 : "Testimonial Updated Successfully",
          showConfirmButton         : false,
          timer                     : 1500,
          toast                     :true,
          position                  :'bottom-end'
        });
        $("#add-testimonial-modal").modal("hide");
      }else{
        helper.StartProcessing($("#save-btn"));
        await addTestimonial(testimonial);
        Swal.fire({
            icon              :'success',
            titleText         :'Testimonial Added Successfully',
            showConfirmButton : false,
            timer             : 1500,
          });
      }
      refetchTestimonials();
      $("#add-testimonial-modal").modal("hide");
    }catch(err){
      helper.StopProcessing($("#save-btn"));
      Swal.fire({
        icon                : 'error',
        titleText           : 'Error!',
        text                :  err.message,
        buttonsStyling      :  false,
        confirmButtonClass  : 'btn btn-brand',
      });
    }
  };

  
  //Modal Opener
  useEffect(() => {
    $("#add-testimonial-modal").modal({
      backdrop: "static",
    });

    $("#add-testimonial-modal").on("hidden.bs.modal", function () {
      $(".modal").hasClass("show") && $("body").addClass("modal-open");
      onDismissModal();
    });

    $("#add-testimonial-modal").modal("toggle");
  }, [show]);


  const deleteImageHandler = () => {
    setBannerImageBlob(null);
    if(testimonial.Image){
      delete testimonial.Image;
      setTestimonialData({...testimonial});
    }
  }


  const showImageCropperHandler = () => { 
    testimonial.Name 
    ? setShowImageCropper(true)
    : Swal.fire(
      { 
        icon: "warning", 
        titleText: "Please Enter Name"
      }
    ) 
  }

  return (
    <>
      <div
        className="modal fade"
        id="add-testimonial-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true">    
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{`${selectedTestimonial ? "Edit" : "Add"} Testimonial `}{" "} </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <div className="testimonial-container">
                <form id="add-member-form">
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="Name"
                          id="Name"
                          placeholder="Name"
                          value={testimonial.Name || ""}
                          onChange={({ target }) => TestimonialDataHandler("Name",helper.titleCase(target.value))}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="Designation"
                          id="Designation"
                          placeholder="Designation"
                          value={testimonial.Designation || ""}
                          onChange={({ target }) => TestimonialDataHandler("Designation", helper.titleCase(target.value))}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          name="Content"
                          id="Content"
                          row="2"
                          placeholder="Content"
                          value={testimonial.Content || ""}
                          onChange={({ target }) => TestimonialDataHandler("Content", target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm form-control"
                          onClick={showImageCropperHandler}>
                          <i className="fa fa-upload mr-2"></i> 
                          {`${bannerImageBlob ||(selectedTestimonial && selectedTestimonial.Image) ? "Update" : "Upload"} Image (200 W x 200 H pixels)`}
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 ">
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          name="Index"
                          id="Index"
                          placeholder="Index"
                          value={testimonial.Index || ""}
                          onChange={({ target }) => TestimonialDataHandler("Index", helper.capitalize(target.value))}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2">
                      <div className="form-group">
                        <div className="d-flex align-item-center">
                          <span className="pt-2 mr-3">Active</span>
                          <span className="kt-switch kt-switch--icon">
                            <label>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                testimonial.Active === "Y" ? "checked" : ""
                                }
                                onChange={() => {
                                testimonial.Active === "Y"
                                ? TestimonialDataHandler("Active", "N")
                                : TestimonialDataHandler("Active", "Y");
                                }}
                              />
                              <span>{testimonial.Active}</span>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                          
                  {( bannerImageBlob || (selectedTestimonial && selectedTestimonial.Image) ) &&
                      <div className="row">
                          <div className="col-12">
                              <div className="kt-notification margin-b30" style={{backgroundColor: '#f7f8fa'}}>
                                  <span className="kt-notification__item">
                                      <div className="kt-notification__item-icon margin-r10" >
                                          <img height="30px" width="30px" src={imageLogo} />
                                      </div>
                                      <div className="kt-notification__item-details" >
                                          <div className="kt-notification__item-title kt-font-bold">
                                              {bannerImageBlob?
                                                  <a href={URL.createObjectURL(bannerImageBlob)} target="_blank" rel="noopener noreferrer">
                                                  {`${bannerImageBlob.name}.${bannerImageBlob.type.split('/')[1]}`}
                                                  </a>
                                                  :
                                                  <a href={testimonial.Image} target="_blank" rel="noopener noreferrer">
                                                  {testimonial.Image.split("WebsiteImages/")[1]}
                                                  </a>
                                              }
                                          </div>
                                      </div>
                                      <button 
                                          type="button"                    
                                          onClick={()=>deleteImageHandler()}
                                          className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                          <i className="flaticon2-rubbish-bin-delete-button"></i>
                                      </button>
                                  </span>
                              </div>
                          </div>
                      </div>
                  }
                </form>
              </div>
                  </div>
                  <div className="modal-footer">
                      <button
                          type="button"
                          className="btn btn-secondary margin-0 margin-r5"
                          data-dismiss="modal"
                          >        
                          Close
                      </button>
                      <button
                          id="update-btn"
                          onClick={submitTestimonialHandler}
                          type="button"
                          className="btn btn-brand margin-0"
                          >       
                          { selectedTestimonial  ? "Update" : "Save" }
                      </button>
                  </div>
              </div>
          </div>
      </div>
      {showImageCropper && (
        <ImageCropper
          show={showImageCropper}
          onDismissModal={() => setShowImageCropper(false)}
          fileAlias = {`${testimonial.Name && testimonial.Name.replace(/\s/g, "-").toLowerCase()}${Date.now()}`}
          imageRatio={{width:200, height:200}}
          DataHandler={(value)=>setBannerImageBlob(value)}
        />
      )}
    </>
  );
};


const Testimonials = (props) => {

    const [loading,                 setLoading]               =  useState(false);
    const [testimonialsData,        setTestimonialsData]      =  useState([]);
    const [selectedTestimonial,     setselectedTestimonial]   =  useState(null);
    const [showAddTestimonial,      setShowAddTestimonial]    =  useState(false);
    const [refresh,                 setRefresh]               =  useState(false);

    
    useEffect(() => {
        if (!showAddTestimonial) setselectedTestimonial(null);
      }, [showAddTestimonial]);
  
  
      useEffect(() => {
        let ignore = false;
        const fetchTestimonialDataAll = async () => {
          try {
            setLoading(true);
            const response = await getTestimonialAll();
            setTestimonialsData(response);
            setLoading(false);
          } catch (error) {
            Swal.fire({
              icon                  : "error",
              titleText             : "Error!",
              text                  : error.message,
              buttonsStyling        : false,
              confirmButtonClass    : "btn btn-brand",
            });
          }
        };
        if (!ignore) fetchTestimonialDataAll();
        return () => {
          ignore = true;
        };
      }, [refresh]);


    const editTestimonialHandler = (testimonial) =>{
        setselectedTestimonial(testimonial);
        setShowAddTestimonial(true);
      }
  
      const toggleTestimonialSwitch =  async (testimonial) => {
        let a = testimonial;
        if (a.Active === "Y") {
          a.Active = "N"
        } else {
          a.Active = "Y"
        }
        if (a.Image) a.Image = a.Image.split("WebsiteImages/")[1];
        await updateTestimonial(a);
      }

   
      const refetchTestimonials = () => setRefresh((r) => !r);


      useEffect(() => { 
        props.title("Testimonials", "menuTestimonials");
      }, []);


    return(
        <>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
            <div className="kt-portlet kt-portlet--mobile">
                <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                    <div className="kt-portlet__head-label">
                        <nav className="file-nav navbar navbar-top">
                            <NavLink activeClassName="top-nav-active" to="/testimonials">
                            All Testimonials
                            </NavLink>
                        </nav>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                        <div className="kt-portlet__head-wrapper">
                            <div className="kt-portlet__head-actions">
                                <button
                                    className="btn btn-brand btn-icon-sm text-white"
                                    onClick={() =>setShowAddTestimonial(true)}>
                                    <i className="la la-plus"></i>
                                    New Testimonial
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kt-portlet__body kt-portlet__body--fit">
                    {loading ? 
                    (<Loading />) : 
                    (<TestimonialTable
                        testimonialsData={testimonialsData}
                        editTestimonialHandler={editTestimonialHandler}
                        toggleTestimonialSwitch={toggleTestimonialSwitch}
                    />)
                    }
                </div>
            </div>  
        </div>
        
      {showAddTestimonial && 
        (
            <AddTestimonials
                show={showAddTestimonial}
                onDismissModal={()=>setShowAddTestimonial(false)}
                selectedTestimonial={selectedTestimonial}
                refetchTestimonials={refetchTestimonials}
            />
        )
      }   
    </>
  )
}


export default Testimonials;