import React, { useEffect, useState } from "react";
import $                              from "jquery";
import Swal                           from "sweetalert2";
import * as helper                    from "../global/Helper";
import BacklinkTable                  from "./BacklinkTable";
import {addAuthor}                    from "../api/blogAPI";
import imageLogo                      from '../../assets/images/ic_image.png';
import ImageCropper                   from "../global/ImageCropper";
import {uploadFile}                   from  "../api/fileAPI";

import "jquery-form";
import "jquery-validation";
import "../../assets/scripts/bootstrap-modal";
import "../../assets/scripts/jquery-validation.init";
import "./AddAuthor.css";



export const AddAuthorBacklink = (props) => {

  const {
    addAuthorBackLinkHandler,
    selectedBacklink,
    updateAuthorBacklinkHandler,
    selectedAuthorID
  } = props;

  const [backlink,    setBacklinkData]    = useState(selectedBacklink ? selectedBacklink : { Active: "Y" });

  useEffect(() => {
    if(selectedAuthorID && selectedAuthorID !== null){
      BacklinkDataHandler('AuthorID',selectedAuthorID);
    }
  }, [selectedAuthorID]);

  
  const BacklinkDataHandler = ( key, value ) => {
    setBacklinkData((data) => {
      return { ...data, [key]: value };
    });
  };

  const addbackLinkHandler = (e) => {
    e.preventDefault();
    $.validator.addMethod(
      "checkType",
      (value, ele, arg) => {
        if (arg !== value) {
          return true;
        } else {
          return false;
        }
      },
      "Please select type"
    );

    let form = $("#attach-backlink-form");
    form.validate({
      rules: {
        Url: {
          required: true,
        },
        Type: {
          checkType: "default",
        },
        Index: {
          required: true,
        },
      },
    });

    if (!form.valid()) {
      return;
    }

    try {

      //send backlink to add author modal or call addAuthorApi to add single backlink
      addAuthorBackLinkHandler(backlink);

      //destory form validation after data submit
      form.validate().destroy();

      //hide backlink modal on add backlink
      $("#add-backlink-modal").modal("hide");
      props.onDismissModal();

      Swal.fire({
        icon              : 'success',
        titleText         : 'Backlink Added Successfully',
        showConfirmButton :  false,
        timer             :  1500,
        toast             :  true,
        position          : 'bottom-end'
      });
    } catch (err) {
      Swal.fire({
        icon                : 'error',
        titleText           : 'Error!',
        text                :  err.message,
        buttonsStyling      :  false,
        confirmButtonClass  : 'btn btn-brand',
      });
    }
    
  };

  // Updating Backlinks Handler
  const updatebacklinksHandler = ( e, backlink ) => {
    e.preventDefault();
    $.validator.addMethod(
      "checkType",
      (value, ele, arg) => {
        if (arg !== value) {
          return true;
        } else {
          return false;
        }
      },
      "Please select type"
    );

    let form = $("#attach-backlink-form");
    form.validate({
      rules: {
        Url: {
          required: true,
        },
        Type: {
          checkType: "default",
        },
        Index: {
          required: true,
        },
      },
    });

    if (!form.valid()) {
      return;
    }

    try {
      // updating the backlinks
      updateAuthorBacklinkHandler( backlink );
      if(backlink.ID){
        Swal.fire({
          icon                :'success',
          titleText           :'Backlink Updated Successfully',
          showConfirmButton   : false,
          timer               : 1500,
          toast               : true,
          position            :'bottom-end',
        });
      }

      // destroy form on updating backlink form
      form.validate().destroy();

      //hide backlink modal on updated backlink
      $("#add-backlink-modal").modal("hide");
      props.onDismissModal();
      
    } catch (err) {
      Swal.fire({
        icon                : 'error',
        titleText           : 'Error!',
        text                :  err.message,
        buttonsStyling      :  false,
        confirmButtonClass  : 'btn btn-brand',
      });
    }
    
  };

  //on props.show
  useEffect(() => {
    $("#add-backlink-modal").modal({
      backdrop: "static",
    });
    $("#add-author-modal").addClass("modal-blur");

    $("#add-backlink-modal").on("hidden.bs.modal", function () {
      $(".modal").hasClass("show") && $("body").addClass("modal-open");
      $("#add-author-modal").removeClass("modal-blur");
      props.onDismissModal();
    });
    $("#add-backlink-modal").modal("toggle");
  }, [props.show]);



  return (
    <div
      className="modal fade"
      id="add-backlink-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true">        
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {`${selectedBacklink ? "Edit" : "Attach"} Backlink`}{" "}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close">    
            </button>
          </div>
          <div className="modal-body">
            <div className="backlink-container">
              <form id="attach-backlink-form">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="url"
                          className="form-control"
                          name="Url"
                          id="Url"
                          placeholder="Url"
                          value={backlink.Url || ""}
                          onChange={({ target }) =>
                            BacklinkDataHandler("Url", target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="row margin-t10">
                      <div className="col-lg-5 col-md-6">
                        <select
                          value={backlink.Type || ""}
                          className="form-control"
                          name="Type"
                          id="Type"
                          onChange={({ target }) =>
                            BacklinkDataHandler("Type", target.value)
                          }>
                          <option value="default" className="text-muted">
                            Select Type
                          </option>
                          <option value="Twitter">    Twitter   </option>
                          <option value="Linkedin">   Linkedin  </option>
                          <option value="Instagram">  Instagram </option>
                          <option value="Facebook">   Facebook  </option>
                          <option value="Youtube">    Youtube   </option>
                          <option value="Others">     Others    </option>
                        </select>
                      </div>
                      <div className="col-lg-5 col-md-6">
                        <input
                          type="number"
                          className="form-control"
                          name="Index"
                          id="Index"
                          placeholder="Index"
                          value={backlink.Index || ""}
                          onChange={({ target }) =>
                            BacklinkDataHandler("Index", parseInt(target.value))
                          }
                        />
                      </div>
                      <div className="col-lg-2 col-md-6 py-3">
                        <div className="d-flex align-item-center">
                          <span className="pt-2 mr-3">Active</span>
                          <span className="kt-switch kt-switch--icon">
                            <label>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  backlink.Active === "Y" ? "checked" : ""
                                }
                                onChange={() => {
                                  backlink.Active === "Y"
                                    ? BacklinkDataHandler("Active", "N")
                                    : BacklinkDataHandler("Active", "Y");
                                }}
                              />
                              <span>{backlink.Active}</span>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary margin-0 margin-r5"
              data-dismiss="modal">       
              Close
            </button>

            <button
              id="update-btn"
              onClick={(e) => {
                selectedBacklink
                ? updatebacklinksHandler(e, backlink)
                : addbackLinkHandler(e);
              }}
              type="button"
              className="btn btn-brand margin-0">       
              {selectedBacklink ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// AddAuthor Modal
const AddAuthor = (props) => {

  const { 
    show, 
    onDismissModal,
    selectedAuthor,
    updateAuthorData,
    refetchAuthors
  } = props;

  const [btnLoading,              setBtnLoading]              =  useState(false);
  const [showAddAuthorBacklink,   setShowAddAuthorBacklink]   =  useState(false);
  const [selectedBacklink,        setSelectedBacklink]        =  useState(null);
  const [author,                  setAuthorData]              =  useState(selectedAuthor? selectedAuthor: { Active: "Y"});
  const [authorbacklinks,         setAuthorBacklinks]         =  useState([]);
  const [bannerImageBlob,         setBannerImageBlob]         =  useState(null);
  const [showImageCropper,        setShowImageCropper]        =  useState(false);



  useEffect(() => {
    if (!showAddAuthorBacklink) setSelectedBacklink(null);
  }, [showAddAuthorBacklink]);

  
  const addAuthorBackLinkHandler = ( backlink ) => {
    if (Object.keys( backlink ).length !== 0) {
      setAuthorBacklinks((data) => [...data, backlink]);
    }
  };

  // updating backlink status from table
  const updateBacklinkStatus = ( active, index ) => {
    setAuthorBacklinks((b) => {
      const newA = [...b];
      newA[index].Active = active;
      return newA;
    });
  };


  //edit selected backlink
  const editBacklinkHandler = ( backlink ) => {
    setSelectedBacklink( backlink );
    setShowAddAuthorBacklink( true );
  };

  // backlink update handler
  const updateAuthorBacklinkHandler = ( backlink ) => {
    const updateBacklink = authorbacklinks;
    updateBacklink[selectedBacklink.mainIndex] = backlink;
    setAuthorBacklinks ( updateBacklink );
    setSelectedBacklink( null );
  };

  // delete backlink handler
  const deleteBacklinkHandler = ( index ) => {
    setAuthorBacklinks((d)=>{
      const newData = [...d];
      newData.splice(index,1);
      return newData;
    })
  };


  //AuthorDataHandler
  const AuthorDataHandler = ( key, value ) => {
    // if(value !== ""){
      setAuthorData((data) => {
        return { ...data, [key]: value };
      });
    // }
  };

  const submitAuthorHandler = async (e) => {
    e.preventDefault();
    var form = $("#add-member-form");
    form.validate().destroy();
    form.validate({
      rules: {
        Name: {
          required: true,
        },
        Email: {
          email: {
            depends: function (element) {
              return $(element).val() !== "";
            },
          },
        },
      },
    });
    
    if (!form.valid()) {
      return;
    }

    //Submit AuthorData
    try{
      
      let fileName ;
      let fileAlias = selectedAuthor ? author.Image.split("WebsiteImages/")[1] :`${author.Name && author.Name.replace(/\s/g, "-").toLowerCase()}${Date.now()}`;
      if(bannerImageBlob) fileName = await uploadFile(bannerImageBlob, fileAlias);
      if(fileName) author.Image = fileName;

      if(selectedAuthor){
        helper.StartProcessing($("#update-btn"));
        if(!bannerImageBlob && author.Image) author.Image = author.Image.split("WebsiteImages/")[1];
        updateAuthorData(author);
        $("#add-author-modal").modal("hide");
    
        Swal.fire({
          icon                :'success',
          titleText           :'Author Updated Successfully',
          showConfirmButton   : false,
          timer               : 1500,
          toast               : true,
          position            :'bottom-end'
        });
      }else{
        helper.StartProcessing($("#save-btn"));
        await addAuthor(author,authorbacklinks);
        refetchAuthors();
        $("#add-author-modal").modal("hide");
        Swal.fire({
          icon              :'success',
          titleText         :'Author Added Successfully',
          showConfirmButton : false,
          timer             : 1500,
        });
      }
    }catch(err){
      helper.StopProcessing($("#save-btn"));
      Swal.fire({
        icon                : 'error',
        titleText           : 'Error!',
        text                :  err.message,
        buttonsStyling      :  false,
        confirmButtonClass  : 'btn btn-brand',
      });
    }
  };


  //Modal Opener
  useEffect(() => {
    $("#add-author-modal").modal({
      backdrop: "static",
    });

    $("#add-author-modal").on("hidden.bs.modal", function () {
      $(".modal").hasClass("show") && $("body").addClass("modal-open");
      onDismissModal();
    });

    $("#add-author-modal").modal("toggle");
  }, [show]);


  const deleteImageHandler = () => {
    setBannerImageBlob(null);
   if(author.Image){
     delete author.Image;
     setAuthorData({...author});
    }
  }
  
  const showImageCropperHandler = () => { 
    author.Name 
    ? setShowImageCropper(true)
    : Swal.fire(
      { 
        icon: "warning", 
        titleText: "Please Enter Name"
      }
    ) 
  }


  return (
    <>
      <div
        className="modal fade"
        id="add-author-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true">    
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{`${selectedAuthor ? "Edit" : "Add"} Author`}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                disabled={btnLoading}>
              </button>
            </div>
            <div className="modal-body">
              <div className="author-container">
                <form id="add-member-form">
                {/*AUTHOR NAME / PROFILE IMAGE */}
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        name="Name"
                        id="Name"
                        placeholder="Name"
                        value={author.Name || ""}
                        onChange={({ target }) =>
                          AuthorDataHandler("Name",helper.titleCase(target.value))
                        }
                      />
                    </div>
                    <div className="col-md-6 py-3">
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm form-control"
                        onClick={showImageCropperHandler}
                      >
                        <i className="fa fa-upload mr-2"></i> 
                        {`${bannerImageBlob ||(selectedAuthor && selectedAuthor.Image) ? "Update" : "Upload"} Image (200 W x 200 H pixels)`}
                      </button>
                    </div>
                 
                  </div>
                      
                  {( bannerImageBlob || (selectedAuthor && selectedAuthor.Image) ) &&
                    <div className="row">
                      <div className="col-12">
                        <div className="kt-notification margin-b30" style={{backgroundColor: '#f7f8fa'}}>
                          <span className="kt-notification__item">
                            <div className="kt-notification__item-icon margin-r10" >
                              <img height="30px" width="30px" src={imageLogo} />
                            </div>
                            <div className="kt-notification__item-details" >
                              <div className="kt-notification__item-title kt-font-bold">
                                {bannerImageBlob?
                                  <a href={URL.createObjectURL(bannerImageBlob)} target="_blank" rel="noopener noreferrer">
                                    {`${bannerImageBlob.name}.${bannerImageBlob.type.split('/')[1]}`}
                                  </a>
                                  :
                                  <a href={author.Image} target="_blank" rel="noopener noreferrer">
                                    {author.Image.split("WebsiteImages/")[1]}
                                  </a>
                                }
                              </div>
                            </div>
                            <button 
                              type="button"                    
                              onClick={()=>deleteImageHandler()}
                              className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                              <i className="flaticon2-rubbish-bin-delete-button"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  }

                  <div className="row margin-t10">
                    <div className="col-lg-6 col-md-6">
                      <input
                        type="email"
                        className="form-control"
                        name="Email"
                        id="Email"
                        placeholder="Email"
                        value={author.Email || ""}
                        onChange={({ target }) =>
                          AuthorDataHandler("Email",(target.value.trim()))
                        }
                      />
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        name="Designation"
                        id="Designation"
                        placeholder="Designation"
                        value={author.Designation || ""}
                        onChange={({ target }) =>
                          AuthorDataHandler("Designation", helper.titleCase(target.value))
                        }
                      />
                    </div>
                    <div className="col-lg-2 col-md-6 py-3">
                      <div className="d-flex align-item-center">
                        <span className="pt-2 mr-3">Active</span>
                        <span className="kt-switch kt-switch--icon">
                          <label>
                            <input
                              type="checkbox"
                              name=""
                              defaultChecked={
                                author.Active === "Y" ? "checked" : ""
                              }
                              onChange={() => {
                                author.Active === "Y"
                                ? AuthorDataHandler("Active", "N")
                                : AuthorDataHandler("Active", "Y");
                              }}
                            />
                            <span>{author.Active}</span>
                          </label>
                        </span>
                      </div>
                    </div>
                </div>
                </form>
              </div>

              {!selectedAuthor && authorbacklinks && authorbacklinks.length > 0  && (
                <BacklinkTable
                  authorbacklinks={authorbacklinks}
                  editBacklinkHandler={editBacklinkHandler}
                  updateBacklinkStatus={updateBacklinkStatus}
                  deleteBacklinkHandler={deleteBacklinkHandler}
                  action="e"
                />
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary margin-0 margin-r5"
                data-dismiss="modal"
                disabled={btnLoading}>        
                Close
              </button>
               
              {!selectedAuthor && (
                <button
                  id="open-btn"
                  onClick={() => setShowAddAuthorBacklink(true)}
                  type="button"
                  className="btn btn-label-brand margin-0 margin-r5">
                  <i className="la la-plus"></i>
                  Backlink
                </button>
              )}

              { selectedAuthor ?
                <button
                  id="update-btn"
                  onClick={submitAuthorHandler}
                  type="button"
                  className="btn btn-brand margin-0"
                  disabled={btnLoading}>              
                  Update
                </button>
                :<button
                  id="update-btn"
                  onClick={submitAuthorHandler}
                  type="button"
                  className="btn btn-brand margin-0"
                  disabled={btnLoading}>       
                  Save
                </button>
              }
              
            </div>
          </div>
        </div>
      </div>

      {showAddAuthorBacklink && (
        <AddAuthorBacklink
          show={showAddAuthorBacklink}
          selectedBacklink={selectedBacklink}
          onDismissModal={() => setShowAddAuthorBacklink(false)}
          addAuthorBackLinkHandler={addAuthorBackLinkHandler}
          updateAuthorBacklinkHandler={updateAuthorBacklinkHandler}
        />
      )}

      {showImageCropper && (
        <ImageCropper
          show={showImageCropper}
          onDismissModal={() => setShowImageCropper(false)}
          fileAlias = {author.Name && `${author.Name.replace(/\s/g, "-").toLowerCase()}${Date.now()}`}
          imageRatio={{width:200, height:200}}
          DataHandler={(value)=>setBannerImageBlob(value)}
        />
      )}
    </>
  );
};
export default AddAuthor;
