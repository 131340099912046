import React, { useState, useEffect } from "react";
import { NavLink }                    from "react-router-dom";
import Swal                           from "sweetalert2";
import Loading                        from "../global/Loading";
import ContactUsTable                 from "./ContactUsTable";
import { getContactUs }               from "../api/homepageAPI";

//Contact Us Main component
const ContactUs = (props) =>{

  const [AllContacts,      setAllContacts]      = useState([]);
  const [loading,          setLoading]          = useState(false);
  const [refresh,          setRefresh]          = useState(false);

  useEffect(() => {
    let ignore = false;
    const fetchContactUsData = async () => {
      try { 
        setLoading(true);
        const response = await getContactUs();
        if(response.length > 0) setAllContacts(response);
        setLoading(false);
      } catch (error) {
        Swal.fire({
          icon                : "error",
          titleText           : "Error!",
          text                : error.message,
          buttonsStyling      : false,
          confirmButtonClass  : "btn btn-brand",
        });
      }
    }
    if (!ignore) fetchContactUsData();
    return () => {
      ignore = true;
    };
  },[refresh])

  useEffect(()=>{
    props.title("Contact Us","menuContactUs");
  },[])

  const refetchContacts = () => setRefresh((r)=>(!r));

  return(
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
          <div className="kt-portlet__head-label">
            <nav className="file-nav navbar navbar-top">
              <NavLink activeClassName="top-nav-active" to="/contactus">
                Contact Us 
              </NavLink>
            </nav>
          </div>
        </div>

        <div className="kt-portlet__body kt-portlet__body--fit">
          {loading ? (
            <Loading />
          ) : (
            <ContactUsTable
              AllContacts = {AllContacts}
              refetchContacts = {refetchContacts}
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default ContactUs;