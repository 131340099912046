import React, { useState, useEffect } from "react";
import { NavLink }                    from "react-router-dom";
import Loading                        from "../global/Loading";
import AddAuthor                      from "./AddAuthor";
import AuthorTable                    from "./AuthorTable";
import {getAuthors}                   from "../api/blogAPI";
import Swal                           from "sweetalert2";


const Authors = (props) => {

  const [authors,            setAuthors]        = useState([]);
  const [loading,            setLoading]        = useState(false);
  const [refresh,            setRefresh]        = useState(false);
  const [showAddAuthor,      setShowAddAuthor]  = useState(false);



  useEffect(() => {
    let ignore = false;
    const fetchAuthors = async () => {
      try {
        setLoading(true);
        const response = await getAuthors();
        setAuthors(response);
        setLoading(false);
      } catch (error) {
        Swal.fire({
          icon                : "error",
          titleText           : "Error!",
          text                : error.message,
          buttonsStyling      : false,
          confirmButtonClass  : "btn btn-brand",
        });
      }
    }
    if (!ignore) fetchAuthors();
    return () => {
      ignore = true;
    };
  },[refresh])


  useEffect(() => { 
    props.title("Authors", "menuAuthors");
  }, []);


  const refetchAuthors = () => setRefresh((r) => !r);
  
  return (
    <React.Fragment>
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
            <div className="kt-portlet__head-label">
              <nav className="file-nav navbar navbar-top">
                <NavLink activeClassName="top-nav-active" to="/authors">
                  All Authors
                </NavLink>
              </nav>
            </div>
            <div className="kt-portlet__head-toolbar">
              <div className="kt-portlet__head-wrapper">
                <div className="kt-portlet__head-actions">
                  <button
                    className="btn btn-brand btn-icon-sm text-white"
                    onClick={() =>setShowAddAuthor(true)}>
                    <i className="la la-plus"></i>
                    New Author
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="kt-grid kt-grid--hor kt-grid--root">
          </div>
            <div className="kt-grid kt-grid--hor kt-grid--root">
            </div>
              <div className="kt-portlet__body kt-portlet__body--fit">
                {loading 
                  ? (<Loading />) 
                  : (
                    <AuthorTable
                      authors={authors}
                      refetchAuthors={() => refetchAuthors()}
                    />
                  )
                }
              </div>
        </div>  
      </div>

      {showAddAuthor && 
        (
        <AddAuthor 
          show={showAddAuthor}
          refetchAuthors={refetchAuthors}
          onDismissModal={()=>setShowAddAuthor(false)}
        />
        )
      }
    
    </React.Fragment>
  );
};

export default Authors;


