import {getAuthState} from "../../assets/scripts/login-util";


export const checkLoginToken=()=>{
    const token = getAuthState('token');
    if(!token){
        window.location.reload();
        return null;
    }
    return token;
}