import React, { useState, useEffect } from "react";
import * as helper from "../global/Helper";
import './Testimonials.css';


const TestimonialTable = (props) => {

    const [testimonialsData, setTestimonialsData] = useState(props.testimonialsData);
    const [showRemoveSearch, setshowRemoveSearch] = useState(false);
 
    const { 
        editTestimonialHandler,
        toggleTestimonialSwitch 
    } = props;

      const searchTestimonialHandler = ({ target }) => {
        if(target.value === ""){
            setshowRemoveSearch(false);
          return setTestimonialsData( props.testimonialsData );
        }

        setshowRemoveSearch(true);
        const matchedTestimonials = props.testimonialsData.filter((t)=>{
          let matchString = `${ t.Name } ${ t.Designation } ${t.Content}`;
          let matchFound  = 0;
    
          const searchArr =  target.value.split(' ');
          searchArr.forEach(term=>{
            matchFound += matchString.toLowerCase().includes(term.toLowerCase()) ? 1 : 0;
          });
    
          return matchFound === searchArr.length;
        });
        setTestimonialsData( matchedTestimonials );
      };

      const clearSearchHandler = () =>{ 
        $("#generalSearch").val("");
        setshowRemoveSearch(false);
        return setTestimonialsData( props.testimonialsData );
      }

    
    useEffect(() => {
        helper.SetScrollHeight();
    });

    return (
        <>
        <div className="kt-form kt-form--label-right kt-margin-b-10">
            <div  className="row align-items-center stickyFilter">
                <div className="col-xl-12 order-2 order-xl-1">
                    <div className="row align-items-center">
                        <div className="col-md-4 kt-margin-b-20-tablet-and-mobile margin-b0">
                            <div className="kt-input-icon kt-input-icon--left">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Testimonials..."
                                    id="generalSearch"
                                    onChange={searchTestimonialHandler}>
                                </input>
                                <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                    <span>
                                        <i className="la la-search"></i>
                                    </span>
                                </span>
                                {showRemoveSearch && (
                                    <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                        <span onClick={clearSearchHandler}>
                                            <i className="la la-close"></i>
                                        </span>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {testimonialsData.length > 0 ? (
                <div className="row">
                    <div className="col-sm-12">
                        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                            <table className="kt-datatable__table">
                                <thead
                                    className="kt-datatable__head"
                                    style={{ display: "block" }}>
                                    <tr className="kt-datatable__row block-row">
                                        <th 
                                            className="kt-datatable__cell text-clip" 
                                            width="15%">
                                            <span>Testimonials</span>
                                        </th>
                                        <th 
                                            className="kt-datatable__cell text-clip" 
                                            width="70%">
                                            <span>Content</span>
                                        </th>
                                        <th
                                            className="kt-datatable__cell--right kt-datatable__cell"
                                            width="15%">
                                            <span className="margin-r40">Action</span>
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div 
                        className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable" 
                        onScroll={helper.setStickyHeader}>
                            <table className="kt-datatable__table table-striped">
                                <tbody
                                    className="kt-datatable__body"
                                    style={{ display: "block" }}>
                                    {testimonialsData.map((t, i) => {
                                        return (
                                            <tr
                                                key={i}
                                                className="kt-datatable__row block-row clickable">
                                                <td
                                                    width="15%"
                                                    title={t.Name}
                                                    className="kt-datatable__cell text-clip">
                                                    <div className="kt-user-card-v2">
                                                        <div className="kt-user-card-v2__pic">
                                                        {t.Image ? <img src={t.Image} alt={t.Name} /> :
                                                            <div className="img-small-profile kt-badge kt-badge--xl kt-badge--info">
                                                                {t.Name.slice(0, 1)}
                                                            </div>
                                                        }
                                                        </div>
                                                    
                                                        <div className="kt-user-card-v2__details text-clip">
                                                            <span className="kt-user-card-v2__name text-clip">
                                                                {t.Name}
                                                            </span>
                                                            {t.Designation && (
                                                                <span 
                                                                className="kt-user-card-v2__desc text-clip" 
                                                                title={t.Designation}>
                                                                {t.Designation.toLowerCase()}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td
                                                    className="kt-datatable__cell text-clip"
                                                    width="70%"
                                                    title={t.Content}>
                                                    <span>{t.Content}</span>
                                                </td>
                                                <td
                                                    className="kt-datatable__cell--right kt-datatable__cell lucid"
                                                    width="15%">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10 "
                                                        onClick={()=>editTestimonialHandler(t,i)}>
                                                        <i className="flaticon2-edit"></i>  
                                                    </button>                                                 
                                                    <span className="kt-switch kt-switch--icon padding-l10 padding-t5 ">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                name=""
                                                                defaultChecked={
                                                                t.Active === "Y" ? "checked" : ""
                                                                }
                                                                onChange={() => {
                                                                    toggleTestimonialSwitch(t)
                                                                }}
                                                            />
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>   
                        </div>
                    </div> 
                </div>
            ):(
                <span className="empty-table-message">No Testimonials Found</span>
            )}
        </div>
    </>);  
};


export default TestimonialTable;