const crypto = require('crypto');

/**
 * @returns {Object} encrypt and decrypt functions to be called on the created object. eg:
 * @param {String} secret Secret key, must be stored as an environment variable and should NEVER change
 * @param {String} algorithm Preferred algorithm, default: aes-256-cbc
 */
export const Encryptor = (secret, algorithm = 'aes-256-cbc') => {
  const key = Buffer.from(
    crypto
      .createHash('sha256')
      .update(String(secret))
      .digest('base64')
      .substr(0, 32)
  );
  /**
   * @returns {String} encrypted text with iv
   * @param {String} text text to be encrypted
   */
  const encrypt = (text) => {
    const iv = crypto.randomBytes(16),
      cipher = crypto.createCipheriv(algorithm, key, iv),
      encrypted = cipher.update(text, 'utf-8', 'hex') + cipher.final('hex');
    return `${encrypted}.${iv.toString('hex')}`;
  };
  /**
   * @returns {String} decrypted text
   * @param {String} encrypted string which was encrypted using the same encryptor object
   */
  const decrypt = (encrypted) => {
    const [code, iv] = encrypted.split('.'),
      decipher = crypto.createDecipheriv(
        'aes-256-cbc',
        key,
        Buffer.from(iv, 'hex')
      ),
      decrypted =
        decipher.update(code, 'hex', 'utf-8') + decipher.final('utf-8');
    return decrypted;
  };
  return { encrypt, decrypt };
};

// example:

// const enc = Encryptor('mySecret'); // mySecret should be environment variable
// const encryptedStr = enc.encrypt(
//   JSON.stringify({
//     bankAccountNo: '2323482342',
//     ifsc: 'test234234',
//     branchName: 'test',
//   })
// );

// // always store encryptedStr in db
// const decryptedStr = enc.decrypt(encryptedStr);
// // decryptedStr is the actual JSON string
// JSON.parse(decryptedStr);
