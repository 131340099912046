import React, { useState, useEffect } from "react";
import * as helper                    from "../global/Helper";
import moment                         from "moment";
import RightSideDetails               from "../global/RightSideDetails";
import "./ContactUs.css";


const ContactUsTable = (props) => {

    const [AllContacts,     setAllContacts]       = useState(props.AllContacts || []);
    const [selectedContact, setSelectedContact]   = useState(null);
    const [showRemoveSearch, setshowRemoveSearch] = useState(false);

    const searchContactHandler = ({target}) => {
      if(target.value === ""){
        setshowRemoveSearch(false);
        return setAllContacts(props.AllContacts);
      }
      setshowRemoveSearch(true);
      const matchedData = props.AllContacts.filter(c => {
        let matchString = `${c.Name} ${c.Email} ${c.Subject} ${c.Message} ${moment(c.CreatedDate).format("DD/MM/YYYY hh:mm A")}`;
        let matchFound = 0;
  
        const searchArr = target.value.split(' ');
        searchArr.forEach(term => {
          matchFound += matchString.toLowerCase().includes(term.toLowerCase()) ? 1 : 0 ;
        });
  
        return matchFound === searchArr.length;
      })
      setAllContacts(matchedData);
    }

    const clearSearchHandler = () =>{ 
      $("#generalSearch").val("");
      setshowRemoveSearch(false);
      return setAllContacts(props.AllContacts);
    }
  
    const closeDrawerHandler = () => {
      setTimeout(() => {
        setSelectedContact(null);
      }, 180);
    };

    useEffect(()=>{
      helper.SetScrollHeight();
    });

    return (
        <div className="kt-form kt-form--label-right kt-margin-b-10">
      <div className="row align-items-center stickyFilter">
        <div className="col-xl-12 order-2 order-xl-1">
          <div className="row align-items-center">
            <div className="col-md-4 kt-margin-b-20-tablet-and-mobile margin-b0">
              <div className="kt-input-icon kt-input-icon--left">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Contacts..."
                  id="generalSearch"
                  onChange={searchContactHandler}>
                </input>
                <span className="kt-input-icon__icon kt-input-icon__icon--left">
                  <span>
                    <i className="la la-search"></i>
                  </span>
                </span>
                {showRemoveSearch && (
                  <span className="kt-input-icon__icon kt-input-icon__icon--right">
                      <span onClick={clearSearchHandler}>
                          <i className="la la-close"></i>
                      </span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {AllContacts.length > 0 ? (
        <div className="row">
          {selectedContact && (
            <RightSideDetails
              onCloseDrawer  =  {closeDrawerHandler}
              show = {!!selectedContact}
              title = "Contact Details">

              <div className="kt-portlet">
                <div className="kt-portlet__body">
                  <div className="kt-widget kt-widget--user-profile-3">
                    <div className="kt-widget__top">
                      <div className="kt-widget__pic kt-widget__pic--brand kt-font-boldest">
                        {selectedContact.Name.slice(0, 1)}
                      </div>
                      <div className="kt-widget__content">
                        <div className="kt-widget__head">
                          <span className="kt-widget__username">
                            {selectedContact.Name}
                          </span>
                        </div>
                        <div className="kt-widget__subhead padding-t0 padding-b0">
                          <span className="padding-r10">
                              <i className="fa fa-clock"></i>
                              {`${moment(selectedContact.CreatedDate).format('DD/MM/YYYY')} - ${moment(selectedContact.CreatedDate).format("hh:mm A")}`}
                          </span>
                        </div>
                        <div className="kt-widget__subhead padding-t0 padding-b0">
                          <span className="padding-r10">
                            <i className="flaticon2-new-email"></i>
                            {selectedContact.Email}
                          </span>
                          {
                            selectedContact.ContactNo && 
                            <span className="padding-r10">
                              <i className="flaticon2-phone"></i>
                              {selectedContact.ContactNo}
                            </span>
                          }
                        </div>
                        {selectedContact.CompanyName &&
                          <div className="kt-widget__subhead padding-t0 padding-b0">
                            <span className="padding-r10">
                              <i className="fa fa-id-card"></i>
                              {selectedContact.CompanyName}
                            </span>
                            {selectedContact.EmployeeStrength &&
                              <span className="padding-r10 mr-10px !important">
                                <i className="fa fa-users"></i>
                                {selectedContact.EmployeeStrength}
                              </span>
                            }
                          </div>
                        }
                      </div>
                    </div>
                    <hr/>
                    <div className="kt-title">
                      <span className="margin-110">{selectedContact.Subject}</span>
                    </div>
                    <div className="margin-110">
                    <span className="kt-desc">{selectedContact.Message}</span>
                    </div>
                  </div>
                </div>
              </div>
              </RightSideDetails>
          )}
          <div className="col-sm-12">
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
              <table className="kt-datatable__table">
                <thead
                  className="kt-datatable__head"
                  style={{ display: "block" }}>
                  <tr className="kt-datatable__row block-row">
                    <th className="kt-datatable__cell text-clip" width="30%">
                      <span>Name</span>
                    </th>
                   
                    <th className="kt-datatable__cell text-clip" width="50%">
                      <span>Message</span>
                    </th>
                    <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="20%">
                      <span>Date</span>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div
              className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable"
              onScroll={helper.setStickyHeader}>
              <table className="kt-datatable__table table-striped">
                <tbody
                  className="kt-datatable__body"
                  style={{ display: "block" }}>
                  {AllContacts.map((c, i) => {
                    return (
                      <tr
                        key={i}
                        className="kt-datatable__row block-row clickable"
                        onClick={()=>setSelectedContact(c)}>
                        <td
                          className="kt-datatable__cell text-clip"
                          width="30%"
                          title={c.Name}>
                            <div className="kt-user-card-v2">
                              <div className="kt-user-card-v2__pic">
                                <div className="kt-badge kt-badge--xl kt-badge--info">
                                  {c.Name.slice(0, 1)}
                                </div>
                              </div>
                              <div className="kt-user-card-v2__details text-clip">
                                <span className="kt-user-card-v2__name text-clip">
                                  {c.Name}
                                </span>
                                {c.Email && (
                                  <span className="kt-user-card-v2__desc text-clip"  title={c.Email}>
                                    {c.Email.toLowerCase()}
                                  </span>
                              )}
                              </div>
                            </div>
                        </td>
                        <td
                          className="kt-datatable__cell text-clip"
                          width="50%"
                          title={c.Subject}>
                          <div className="kt-user-card-v2">
                            <div className="kt-user-card-v2__details text-clip">
                              <span className="kt-user-card-v2__name text-clip">
                                {c.Subject}
                              </span>
                              {c.Message && (
                                <span className="kt-user-card-v2__desc text-clip"  title={c.Message}>
                                  {c.Message.toLowerCase()}
                                </span>
                            )}
                            </div>
                          </div>
                        </td>
                        <td
                          className="kt-datatable__cell--center kt-datatable__cell text-clip"
                          width="20%"
                          title= {`${moment(c.CreatedDate).format('DD/MM/YYYY')} - ${moment(c.CreatedDate).format("hh:mm A")}`}>
                          <span> {`${moment(c.CreatedDate).format('DD/MM/YYYY')} - ${moment(c.CreatedDate).format("hh:mm A")}`}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <span className="empty-table-message">No Contact Us Found</span>
      )}
    </div>
  );

    
}

export default ContactUsTable;

