import React, { useState, useEffect } from "react";
import $ from "jquery";
import "jquery-form";
import "jquery-validation";
import "../../assets/scripts/bootstrap-modal";
import "../../assets/scripts/jquery-validation.init";
import Dropzone from "./dropzone";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Swal from "sweetalert2";
import * as helper from "./Helper";


const ImageCropper = (props) => {
  const { DataHandler, fileAlias , onPage,  imageRatio } = props;
  
  const [file, setFile] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [image,setImage]=useState(null);
  
  const [cropSetting, setCropSetting] = useState({
    x: 0,
    y: 0,
    unit:'px',
    width: imageRatio.width,
    height: imageRatio.height
  });


  const AdjustCropper = (img) => {

    setImage(img);
    if (img) {
      if (img.width < imageRatio.width || img.height < imageRatio.height) {
        if (img.height < imageRatio.height && img.width < imageRatio.width){
          setCropSetting({
            x: 0,
            y: 0,
            unit:'px',
            width: img.width,
            height:img.height
          })
        } else if(img.height < imageRatio.height ){

          setCropSetting({
            x: 0,
            y: 0,
            unit:'px',
            width: imageRatio.width,
            height:img.height
          })
        } else{

          setCropSetting({
            x: 0,
            y: 0,
            unit:'px',
            width: img.width,
            height:imageRatio.height
          })
        }
      };
    }
    return(false)
  }


  const getCroppedImg = (image, crop) => { 
    return new Promise((resolve,reject)=>{
      
      if (image) {
        const canvas              = document.createElement("canvas");
        const scaleX              = image.naturalWidth / image.width;
        const scaleY              = image.naturalHeight / image.height;
        canvas.width              = crop.width;
        canvas.height             = crop.height;
        const ctx                 = canvas.getContext("2d");
        ctx.fillStyle             = "#FFF";
        ctx.imageSmoothingQuality = 'high';
        ctx.strokeStyle           = '#ffffff';
        ctx.shadowColor           = "rgba(255, 255, 255, 0.5)"

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        canvas.toBlob(
          (blob) => {
              if (blob !== null) {
                blob.name = fileAlias;
                //CROPPED IMAGE BLOB
                resolve(blob)
                setCroppedImage( blob );
              }
            },
            "image/png",
            1
          )
      };
    })
  }

  
  const CropAndSave = async() => {
    if (!fileAlias) {
      return Swal.fire({
        icon: "warning",
        titleText: "Please Enter Alias",
        buttonsStyling: false,
        confirmButtonClass: "btn btn-brand",
      });
      
    }
    if(!image){
     return Swal.fire({
        icon:"warning",
        titleText: "Please select image!",
        buttonsStyling: false,
        confirmButtonClass: "btn btn-brand",
      });
    }
    // Call upload Api & return the image url
    try {
      helper.StartProcessing($("#fileUpload-btn"));
      const ImageBlob = await getCroppedImg(image,cropSetting)  
      if(ImageBlob) {
        DataHandler(ImageBlob)
        $("#image-cropper-modal").modal("hide");
      }
      
    } catch (err) {
      helper.StopProcessing($("#fileUpload-btn"));
      Swal.fire({
        icon: "error",
        titleText: "Error!",
        text: err.message,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-brand",
      });
    }
  };

  //Modal Show /Blur Other modals
  useEffect(() => {
    $("#image-cropper-modal").modal({
      backdrop: "static",
    });
    $("#add-knowledgebasecategory-modal").addClass("modal-blur");
    $("#add-blog-modal").addClass("modal-blur");
    $("#image-cropper-modal").on("hidden.bs.modal", function () {
      $(".modal").hasClass("show") && $("body").addClass("modal-open");
      $("#add-blog-modal").removeClass("modal-blur");
      $("#add-knowledgebasecategory-modal").removeClass("modal-blur");
      props.onDismissModal();
    });
    $("#image-cropper-modal").modal("toggle");
  }, [props.show]);


  return (
    <div
      className="modal fade"
      id="image-cropper-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      {/* Header Starts */}
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Crop Image</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          {/* Header Ends */}


          {/* Body  Starts*/}
          <div className="modal-body">
              {
              croppedImage?(<img src = {window.URL.createObjectURL(croppedImage)} className="img-fluid"></img>)
             : file ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "25rem", minWidth: "25rem"  }}
                >
                  <ReactCrop
                  src={file} 
                  onImageLoaded={AdjustCropper}
                  onChange={setCropSetting}
                  crop={cropSetting}
                  minWidth={1000}
                  minHeight={500}
                  maxWidth={1000}
                  maxHeight={500}
                  keepSelection={true}
                  />
                </div>
              ) : (
                <Dropzone
                fileHandler={(f) =>{setFile(window.URL.createObjectURL(f))}}
                accept="image/*"
                />
              )}
          </div>
          {/* Body Ends */}

          {/* Footer Starts */}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary margin-0 margin-r5"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              id="fileUpload-btn"
              type="button"
              className="btn btn-brand margin-0"
              onClick={CropAndSave}
            >
              Crop & Save
            </button>
          </div>
          {/* Footer Ends */}
        </div>
      </div>
    </div>
  );
};

export default ImageCropper;
