import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import "../../assets/styles/dark.css";
import BannerLogoLight from "../../assets/images/ClubEZ-banner.png";
import LogoLight from "../../assets/images/logo-light.png";

const SideMenu = (props) => {
  useEffect(() => {
    $(".kt-menu__item").removeClass("kt-menu__item--active");
    $("#" + props.menuID).addClass("kt-menu__item--active");
  });

  const toggleTitle = () => {
    if ($("body").hasClass("kt-aside--minimize")) {

      $('#menuContactUs .kt-menu__link-icon .kt-svg-icon').attr(
        'data-original-title',
        'Contact Us'
      );

      $("#menuTestimonials .kt-menu__link-icon .kt-svg-icon").attr(
        "data-original-title",
        "Testimonials"
      );

      $("#menuClients .kt-menu__link-icon .kt-svg-icon").attr(
        "data-original-title",
        "Clients"
      );

      $("#menuAuthors .kt-menu__link-icon .kt-svg-icon").attr(
        "data-original-title",
        "Authors"
      );
      $("#menuBlog .kt-menu__link-icon .kt-svg-icon").attr(
        "data-original-title",
        "Blog"
      );
      $("#menuBlogTagMaster .kt-menu__link-icon .kt-svg-icon").attr(
        "data-original-title",
        "Blog Tag Master"
      );
      $(".kt-aside__brand-tools").addClass("aside-brand-tools");
      $("#asideBrandLogo").attr("src", LogoLight);
      $("#asideBrandLogo").attr("height", "30");
    } else {
    
      $(".kt-menu__link-icon .kt-svg-icon").removeAttr("data-original-title");
      $(".kt-aside__brand-tools").removeClass("aside-brand-tools");
      $("#asideBrandLogo").attr("src", BannerLogoLight);
      $("#asideBrandLogo").attr("height", "22");
    }
  };


  return (
    <div
      className="kt-aside kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop"
      id="kt_aside"
    >
      <div className="kt-aside__brand kt-grid__item margin-b0" id="kt_aside_brand"
      >
        <div className="kt-aside__brand-logo">
          <NavLink disabled={props.disabled} to="/">
            <img
              id="asideBrandLogo"
              alt="Logo"
              src={BannerLogoLight}
              height="22"
            />
          </NavLink>
        </div>
        <div className="kt-aside__brand-tools">
          <button
            onClick={() => toggleTitle()}
            className="kt-aside__brand-aside-toggler"
            id="kt_aside_toggler"
          >
            <span>
              <svg
                className="nav-movment"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                focusable="false"
                role="presentation"
              >
                <path
                  d="M13.706 9.698a.988.988 0 0 0 0-1.407 1.01 1.01 0 0 0-1.419 0l-2.965 2.94a1.09 1.09 0 0 0 0 1.548l2.955 2.93a1.01 1.01 0 0 0 1.42 0 .988.988 0 0 0 0-1.407l-2.318-2.297 2.327-2.307z"
                  fill="currentColor"
                  fillRule="evenodd"
                ></path>
              </svg>
            </span>
            <span>
              <svg
                className="nav-movment margin-l65"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                focusable="false"
                role="presentation"
              >
                <path
                  d="M10.294 9.698a.988.988 0 0 1 0-1.407 1.01 1.01 0 0 1 1.419 0l2.965 2.94a1.09 1.09 0 0 1 0 1.548l-2.955 2.93a1.01 1.01 0 0 1-1.42 0 .988.988 0 0 1 0-1.407l2.318-2.297-2.327-2.307z"
                  fill="currentColor"
                  fillRule="evenodd"
                ></path>
              </svg>
            </span>
          </button>
        </div>
      </div>

      <div
        className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
        id="kt_aside_menu_wrapper"
        >
        <div
          id="kt_aside_menu"
          className="kt-aside-menu  "
          data-ktmenu-vertical="1"
          data-ktmenu-scroll="1"
          data-ktmenu-dropdown-timeout="500"
          >
          <ul style={{ paddingTop: "0px" }} className="kt-menu__nav ">
            <li className="kt-menu__section">
              <h4 className="kt-menu__section-text">Home Page</h4>
              <i className="kt-menu__section-icon flaticon-more-v2"></i>
            </li>
            
            <li
              id="menuContactUs"
              className="kt-menu__item kt-menu__item--active"
              aria-haspopup="true"
            >
              <NavLink className="kt-menu__link " to="/contactus">
                <span data-original-title="Contact Us" className="kt-menu__link-icon" >
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    className="kt-svg-icon"
                    data-toggle="kt-tooltip"
                    data-placement="right"
                   
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M9.8604543,6.01162174 C9.94073619,5.93133984 10.0459506,5.88077119 10.1587919,5.86823326 C10.4332453,5.83773844 10.6804547,6.03550595 10.7109496,6.30995936 L11.2341533,11.0187935 C11.2382309,11.0554911 11.2382309,11.0925274 11.2341533,11.129225 C11.2036585,11.4036784 10.9564491,11.6014459 10.6819957,11.5709511 L5.97316161,11.0477473 C5.86032028,11.0352094 5.75510588,10.9846407 5.67482399,10.9043588 C5.47956184,10.7090967 5.47956184,10.3925142 5.67482399,10.197252 L7.06053236,8.81154367 L5.55907018,7.31008149 C5.36380803,7.11481935 5.36380803,6.79823686 5.55907018,6.60297471 L6.26617696,5.89586793 C6.46143911,5.70060578 6.7780216,5.70060578 6.97328374,5.89586793 L8.47474592,7.39733011 L9.8604543,6.01162174 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M12.0799676,14.7839934 L14.2839934,12.5799676 C14.8927139,11.9712471 15.0436229,11.0413042 14.6586342,10.2713269 L14.5337539,10.0215663 C14.1487653,9.25158901 14.2996742,8.3216461 14.9083948,7.71292558 L17.6411989,4.98012149 C17.836461,4.78485934 18.1530435,4.78485934 18.3483056,4.98012149 C18.3863063,5.01812215 18.4179321,5.06200062 18.4419658,5.11006808 L19.5459415,7.31801948 C20.3904962,9.0071287 20.0594452,11.0471565 18.7240871,12.3825146 L12.7252616,18.3813401 C11.2717221,19.8348796 9.12170075,20.3424308 7.17157288,19.6923882 L4.75709327,18.8875616 C4.49512161,18.8002377 4.35354162,18.5170777 4.4408655,18.2551061 C4.46541191,18.1814669 4.50676633,18.114554 4.56165376,18.0596666 L7.21292558,15.4083948 C7.8216461,14.7996742 8.75158901,14.6487653 9.52156634,15.0337539 L9.77132688,15.1586342 C10.5413042,15.5436229 11.4712471,15.3927139 12.0799676,14.7839934 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                </span>

                <span className="kt-menu__link-text">Contact Us</span>
              </NavLink>
            </li>
            
            <li
              id="menuTestimonials"
              className="kt-menu__item kt-menu__item--active"
              aria-haspopup="true"
            >
              <NavLink className="kt-menu__link " to="/testimonials">
                <span  className="kt-menu__link-icon" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1"  className="kt-svg-icon"  data-toggle="kt-tooltip"
                    data-placement="right">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                      <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                      <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1"/>
                      <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1"/>
                    </g>
                  </svg>
                </span>

                <span className="kt-menu__link-text">Testimonials</span>
              </NavLink>
            </li>

            <li
              id="menuClients"
              className="kt-menu__item kt-menu__item--active"
              aria-haspopup="true"
            >
              <NavLink className="kt-menu__link " to="/clients">
                <span  className="kt-menu__link-icon" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1"  className="kt-svg-icon"  data-toggle="kt-tooltip"
                    data-placement="right">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"/>
                    <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                    <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
                    </g>
                  </svg>
                </span>

                <span className="kt-menu__link-text">Clients</span>
              </NavLink>
            </li>
            
            <li className="kt-menu__section ">
              <h4 className="kt-menu__section-text">Blogs</h4>
              <i className="kt-menu__section-icon flaticon-more-v2"></i>
            </li>
            <li id="menuAuthors" className="kt-menu__item" aria-haspopup="true">
              <NavLink
               
                to="/authors"
                className="kt-menu__link "
              >
                <span className="kt-menu__link-icon">
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    className="kt-svg-icon"
                    data-toggle="kt-tooltip"
                    data-placement="right"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                    </g>
                  </svg>
                </span>
                <span className="kt-menu__link-text">Authors</span>
              </NavLink>
            </li>
            <li
              id="menuBlog"
              className="kt-menu__item kt-menu__item--active"
              aria-haspopup="true"
            >
              <NavLink
             
                to="/blog"
                className="kt-menu__link "
              >
                <span data-original-title="Blog" className="kt-menu__link-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon"  data-toggle="kt-tooltip"
                    data-placement="right">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <path d="M4.5,3 L19.5,3 C20.3284271,3 21,3.67157288 21,4.5 L21,19.5 C21,20.3284271 20.3284271,21 19.5,21 L4.5,21 C3.67157288,21 3,20.3284271 3,19.5 L3,4.5 C3,3.67157288 3.67157288,3 4.5,3 Z M8,5 C7.44771525,5 7,5.44771525 7,6 C7,6.55228475 7.44771525,7 8,7 L16,7 C16.5522847,7 17,6.55228475 17,6 C17,5.44771525 16.5522847,5 16,5 L8,5 Z" fill="#000000"/>
                    </g>
                  </svg>
                </span>
                <span className="kt-menu__link-text">Blog</span>
              </NavLink>
            </li>
            <li
              id="menuBlogTagMaster"
              className="kt-menu__item  kt-menu__item--active"
              aria-haspopup="true"
            >
              <NavLink
                
                to="/blog-tag-master"
                className="kt-menu__link "
              >
                <span className="kt-menu__link-icon">
                  <svg
                   width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    className="kt-svg-icon"
                    data-toggle="kt-tooltip"
                    data-placement="right"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M11.7573593,15.2426407 L8.75735931,15.2426407 C8.20507456,15.2426407 7.75735931,15.6903559 7.75735931,16.2426407 C7.75735931,16.7949254 8.20507456,17.2426407 8.75735931,17.2426407 L11.7573593,17.2426407 L11.7573593,18.2426407 C11.7573593,19.3472102 10.8619288,20.2426407 9.75735931,20.2426407 L5.75735931,20.2426407 C4.65278981,20.2426407 3.75735931,19.3472102 3.75735931,18.2426407 L3.75735931,14.2426407 C3.75735931,13.1380712 4.65278981,12.2426407 5.75735931,12.2426407 L9.75735931,12.2426407 C10.8619288,12.2426407 11.7573593,13.1380712 11.7573593,14.2426407 L11.7573593,15.2426407 Z"
                        fill="#000000"
                        opacity="0.3"
                        transform="translate(7.757359, 16.242641) rotate(-45.000000) translate(-7.757359, -16.242641) "
                      />
                      <path
                        d="M12.2426407,8.75735931 L15.2426407,8.75735931 C15.7949254,8.75735931 16.2426407,8.30964406 16.2426407,7.75735931 C16.2426407,7.20507456 15.7949254,6.75735931 15.2426407,6.75735931 L12.2426407,6.75735931 L12.2426407,5.75735931 C12.2426407,4.65278981 13.1380712,3.75735931 14.2426407,3.75735931 L18.2426407,3.75735931 C19.3472102,3.75735931 20.2426407,4.65278981 20.2426407,5.75735931 L20.2426407,9.75735931 C20.2426407,10.8619288 19.3472102,11.7573593 18.2426407,11.7573593 L14.2426407,11.7573593 C13.1380712,11.7573593 12.2426407,10.8619288 12.2426407,9.75735931 L12.2426407,8.75735931 Z"
                        fill="#000000"
                        transform="translate(16.242641, 7.757359) rotate(-45.000000) translate(-16.242641, -7.757359) "
                      />
                      <path
                        d="M5.89339828,3.42893219 C6.44568303,3.42893219 6.89339828,3.87664744 6.89339828,4.42893219 L6.89339828,6.42893219 C6.89339828,6.98121694 6.44568303,7.42893219 5.89339828,7.42893219 C5.34111353,7.42893219 4.89339828,6.98121694 4.89339828,6.42893219 L4.89339828,4.42893219 C4.89339828,3.87664744 5.34111353,3.42893219 5.89339828,3.42893219 Z M11.4289322,5.13603897 C11.8194565,5.52656326 11.8194565,6.15972824 11.4289322,6.55025253 L10.0147186,7.96446609 C9.62419433,8.35499039 8.99102936,8.35499039 8.60050506,7.96446609 C8.20998077,7.5739418 8.20998077,6.94077682 8.60050506,6.55025253 L10.0147186,5.13603897 C10.4052429,4.74551468 11.0384079,4.74551468 11.4289322,5.13603897 Z M0.600505063,5.13603897 C0.991029355,4.74551468 1.62419433,4.74551468 2.01471863,5.13603897 L3.42893219,6.55025253 C3.81945648,6.94077682 3.81945648,7.5739418 3.42893219,7.96446609 C3.0384079,8.35499039 2.40524292,8.35499039 2.01471863,7.96446609 L0.600505063,6.55025253 C0.209980772,6.15972824 0.209980772,5.52656326 0.600505063,5.13603897 Z"
                        fill="#000000"
                        opacity="0.3"
                        transform="translate(6.014719, 5.843146) rotate(-45.000000) translate(-6.014719, -5.843146) "
                      />
                      <path
                        d="M17.9142136,15.4497475 C18.4664983,15.4497475 18.9142136,15.8974627 18.9142136,16.4497475 L18.9142136,18.4497475 C18.9142136,19.0020322 18.4664983,19.4497475 17.9142136,19.4497475 C17.3619288,19.4497475 16.9142136,19.0020322 16.9142136,18.4497475 L16.9142136,16.4497475 C16.9142136,15.8974627 17.3619288,15.4497475 17.9142136,15.4497475 Z M23.4497475,17.1568542 C23.8402718,17.5473785 23.8402718,18.1805435 23.4497475,18.5710678 L22.0355339,19.9852814 C21.6450096,20.3758057 21.0118446,20.3758057 20.6213203,19.9852814 C20.2307961,19.5947571 20.2307961,18.9615921 20.6213203,18.5710678 L22.0355339,17.1568542 C22.4260582,16.76633 23.0592232,16.76633 23.4497475,17.1568542 Z M12.6213203,17.1568542 C13.0118446,16.76633 13.6450096,16.76633 14.0355339,17.1568542 L15.4497475,18.5710678 C15.8402718,18.9615921 15.8402718,19.5947571 15.4497475,19.9852814 C15.0592232,20.3758057 14.4260582,20.3758057 14.0355339,19.9852814 L12.6213203,18.5710678 C12.2307961,18.1805435 12.2307961,17.5473785 12.6213203,17.1568542 Z"
                        fill="#000000"
                        opacity="0.3"
                        transform="translate(18.035534, 17.863961) scale(1, -1) rotate(45.000000) translate(-18.035534, -17.863961) "
                      />
                    </g>
                  </svg>
                </span>
                <span className="kt-menu__link-text">Blog Tag Master</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
