import React from "react";
import axios from "axios";

const getErrorMessage = (err) =>
  err.response
    ? err.response.data && err.response.data.message
    : "Unknown error";

export const axiosPost = async (url, data, config) => {
  try {
    const { data: response } = await axios.post(url, data, config);
    return response;
  } catch (err) {
    const message = getErrorMessage(err);
    throw new Error(message || err.message || "Unknown error");
  }
};

export const axiosGet = async (url, config) => {
  try {
    const { data } = await axios.get(url, config);
    return data;
  } catch (err) {
    const message = getErrorMessage(err);
    throw new Error(message || err.message || "Unknown error");
  }
};

export const axiosPatch = async (url, data, config) => {
  try {
    const { data: response } = await axios.patch(url, data, config);
    return response;
  } catch (err) {
    const message = getErrorMessage(err);
    throw new Error(message || err.message || "Unknown error");
  }
};

export const axiosDelete = async (url, data, config) => {
  try {
    const { data: response } = await axios.delete(url, data, config);
    return response;
  } catch (err) {
    const message = getErrorMessage(err);
    throw new Error(message || err.message || "Unknown error");
  }
};
