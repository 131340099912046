import { axiosPost, axiosGet,axiosPatch } from "./axiosFunctions";
import {checkLoginToken} from "./common";

const addAuthorUrl            ="/api/blog/addAuthor";
const addAuthorBacklinkUrl    ="/api/blog/addAuthorBacklink";
const addBlogUrl              ="/api/blog/addBlog";
const addBlogTagMasterUrl     ="/api/blog/addBlogTagMaster";
const getAuthorUrl            ="/api/blog/getAuthors";
const getAuthorBacklinksUrl   ="/api/blog/getAuthorBacklinks";
const getBlogAllUrl           ="/api/blog/getBlogAll";
const getBlogTagsUrl          ="/api/blog/getBlogTagsByBlogId";
const getBlogTagMasterAllUrl  ="/api/blog/getBlogTagMasterAll";
const checkIfBlogExistsUrl    ="/api/blog/checkIfBlogExists";
const updateAuthorUrl         ="/api/blog/updateAuthor";
const updateAuthorBacklinkUrl = "/api/blog/updateAuthorBacklink";
const updateBlogUrl           ="/api/blog/updateBlog";
const updateBlogTagMasterUrl  ="/api/blog/updateBlogTagMaster";




//ADD OR POST  API's

//Author
export const addAuthor = async(author,backlinks)=>{
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosPost(addAuthorUrl,
    {author,backlinks},
    { headers: { auth_token: `bearer ${token}` } }
    );
  return response;
};
//Backlinks
export const addAuthorBacklink = async(backlink)=>{
  const token = checkLoginToken();
  if(!token) return;
  const response = await axiosPost(addAuthorBacklinkUrl,
    {backlink},
    {headers:{auth_token:`bearer ${token}`}}
  );
  return response;
};
//Blogs
export const addBlog = async(blogData,blogTags)=>{
  const token = checkLoginToken();
  if(!token) return;
  const response = await axiosPost(addBlogUrl,
    {blogData,blogTags},
    {headers:{auth_token:`bearer ${token}`}}
    );
  return response;
};
//Blog Tag Master
export const addBlogTagMaster = async (blogTag) => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosPost(
    addBlogTagMasterUrl,
    { blogTag },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};



//GET API's

//Author
export const getAuthors = async () => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    getAuthorUrl,
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};
//Backlinks
export const getAuthorBacklinks = async (authorID)=>{
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    `${getAuthorBacklinksUrl}/${authorID}`,
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};
//Blogs
export const getBlogs = async () => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    getBlogAllUrl,
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};
//BlogTags
export const getBlogTags = async(blogID)=>{
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    `${getBlogTagsUrl}/${blogID}`,
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};
//Blog Tag Master
export const getBlogTagMasterAll = async () => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    getBlogTagMasterAllUrl,
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};
//Check Blog Exists
export const checkIfBlogExists = async (blogAlias) => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosGet(
    `${checkIfBlogExistsUrl}/${blogAlias}`,
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};



//UPDATE/PATCH Api's

//Author
export const updateAuthor = async(author)=>{
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosPatch(updateAuthorUrl,
  {author},
  { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};
//Backlinks
export const updateAuthorBacklink = async(backlink)=>{
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosPatch(updateAuthorBacklinkUrl,
    {backlink},
    { headers: { auth_token: `bearer ${token}` } }
    );
  return response;
};
//Blogs
export const updateBlog = async(blogData,blogTags,isTagEdited)=>{
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosPatch(updateBlogUrl,
  {blogData,blogTags,isTagEdited},
  { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};
//Blog Tag Master
export const updateBlogTagMaster = async (blogTag) => {
  const token = checkLoginToken();
  if (!token) return;
  const response = await axiosPatch(
    updateBlogTagMasterUrl,
    { blogTag },
    { headers: { auth_token: `bearer ${token}` } }
  )
  return response;
};