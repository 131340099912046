import React, { useState }        from "react";
import { Route, Switch,Redirect } from "react-router-dom";
import Header                     from "../components/global/Header";
import SideMenu                   from "../components/global/SideMenu";
import ContactUs                  from "../components/contactUs/ContactUs";
import Testimonials               from "../components/testimonials/Testimonials"
import Clients                    from "../components/client/Client";
import Authors                    from "../components/authors/Authors";
import Blog                       from "../components/blog/Blog";
import Tagmaster                  from "../components/blog/Tagmaster";
import "./App.css";

const Admin = () => {
  const [headerTitle,     setHeaderTitle] = useState("Authors");
  const [menuId,               setMenuId] = useState("menuAuthors");



  const toggleState = (title, menuId) => {
    setHeaderTitle(title);
    setMenuId(menuId);
  };


  return (
    <div className="kt-grid kt-grid--hor kt-grid--root">
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <SideMenu menuID={menuId} />
        <div
          className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
          id="kt_wrapper">
          <Header title={headerTitle} />
          <div
            style={{ paddingTop: "0px" }}
            className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0"
            id="kt_content">
            <Switch>
             <Route path="/contactus">
               <ContactUs title={toggleState} />
             </Route>
             <Route path="/testimonials">
               <Testimonials title={toggleState} />
             </Route>
             <Route path="/clients">
               <Clients title={toggleState} />
             </Route>
              <Route path="/blog">
                <Blog title={toggleState} />
              </Route>
              <Route path="/blog-tag-master">
                <Tagmaster title={toggleState} />
              </Route>
              <Route path="/authors">
                <Authors title={toggleState} />
              </Route>

              {/* <Route path="/"></Route> */}
              <Redirect to="/contactus" />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
