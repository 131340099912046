import React, { useState, useEffect }                                 from "react";
import { NavLink }                                                    from "react-router-dom";
import $                                                              from "jquery";
import Swal                                                           from "sweetalert2";
import Loading                                                        from "../global/Loading";
import * as helper                                                    from "../global/Helper";
import TagTable                                                       from "./TagTable";
import { addBlogTagMaster, getBlogTagMasterAll, updateBlogTagMaster}  from "../api/blogAPI";
import "jquery-form";
import "jquery-validation";
import "../../assets/scripts/bootstrap-modal";
import "../../assets/scripts/jquery-validation.init";

//Add Tag Modal Starts ---->
export const AddBlogTag = (props) => {

  const {
    show,
    onDismissModal,
    selectedTag,
    refetchBlogTagsMaster
  } = props;

  const [btnLoading,    setBtnLoading] = useState(false);
  const [blogTag,       setblogTag] = useState(selectedTag ? selectedTag : { Active: "Y" });

  //TagDataHandler
  const TagDataHandler = ( key, value ) => {
    setblogTag((data) => {
      return { ...data, [key]: value };
    });
  };

  //Add Blog Tag Handler
  const addBlogTagHandler = async (e) => {
    e.preventDefault();

    var form = $("#add-blogtag-form");
    form.validate().destroy();

    form.validate({
      rules: {
        TagName: {
          required: true,
        },
        Index: {
          required: true,
        },
      },
    });

    if (!form.valid()) {
      return;
    }

    try {               
      helper.StartProcessing($("#save-btn"));
      if (selectedTag) {
        await updateBlogTagMaster(blogTag);
        Swal.fire({
          icon                : "success",
          titleText           : "Blog Tag updated Successfully",
          showConfirmButton   : false,
          timer               : 1500,
          toast               : true,
          position            :'bottom-end'
        });
      } else {
        await addBlogTagMaster(blogTag);
        Swal.fire({
          icon                : "success",
          titleText           : "Blog Tag Added Successfully",
          showConfirmButton   : false,
          timer               : 1500,
          toast               : true,
          position            :'bottom-end'
        });
      }
      refetchBlogTagsMaster();
      $("#add-blogtag-modal").modal("hide");
      Swal.fire({
        icon                  :"success",
        titleText             :"Blog Tag Added Successfully",
        showConfirmButton     : false,
        timer                 : 1500,
        toast                 : true,
        position              :'bottom-end'
      });
    } catch (err) {
      Swal.fire({
        icon                  : "error",
        titleText             : "Error!",
        text                  : err.message,
        buttonsStyling        : false,
        confirmButtonClass    : "btn btn-brand",
      });
    }
  };


  useEffect(() => {
    $("#add-blogtag-modal").modal({
      backdrop: "static",
    });

    $("#add-blogtag-modal").on("hidden.bs.modal", function () {
      $(".modal").hasClass("show") && $("body").addClass("modal-open");
      onDismissModal();
    });
    $("#add-blogtag-modal").modal("toggle");
  }, [show]);


  return (
    <div
      className="modal fade"
      id="add-blogtag-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
            {`${selectedTag ? "Edit" : "Add"} Blog Tag`}{" "}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              disabled={btnLoading}>
            </button>
          </div>
          <div className="modal-body">
            <div className="author-container">
              <form id="add-blogtag-form">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-5 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          name="TagName"
                          id="TagName"
                          placeholder="Tag Name"
                          value={blogTag.TagName || ""}
                          onChange={({ target }) =>
                            TagDataHandler("TagName", helper.titleCase(target.value))
                          }
                        />
                      </div>
                      <div className="col-lg-5 col-md-6">
                        <input
                          type="number"
                          className="form-control"
                          name="Index"
                          id="Index"
                          placeholder="Index"
                          value={blogTag.Index || ""}
                          onChange={({ target }) =>
                            TagDataHandler("Index",target.value)
                          }
                        />
                      </div>
                      <div className="col-lg-2 col-md-6 py-3">
                        <div className="d-flex align-item-center">
                          <span className="pt-2 mr-3">Active</span>
                          <span className="kt-switch kt-switch--icon">
                            <label>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  blogTag.Active === "Y" ? "checked" : ""
                                }
                                onChange={() => {
                                  blogTag.Active === "Y"
                                  ? TagDataHandler("Active", "N")
                                  : TagDataHandler("Active", "Y");
                                }}
                              />
                              <span>{blogTag.Active}</span>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary margin-0 margin-r5"
              data-dismiss="modal"
              disabled={btnLoading}>
              Close
            </button>

            <button
              id="save-btn"
              onClick={ addBlogTagHandler }
              type="button"
              className= "btn btn-brand margin-0">
              {selectedTag ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
//Add Tag Modal Ends ---->


// Main Tags Master Component
const Tagmaster = (props) => {

  const [loading,             setLoading]        = useState(false);
  const [allBlogTags,         setallBlogTags]    = useState([]);
  const [selectedTag,         setSelectedTag]    = useState(null);
  const [refresh,             setRefresh]        = useState(false);
  const [showAddBlogTag,      setshowAddBlogTag] = useState(false);

  useEffect(() => {
    let ignore = false;
    const fetchBlogTagsAll = async () => {
      try {
        setLoading(true);
        const response = await getBlogTagMasterAll();
        setallBlogTags(response);
        setLoading(false);
      } catch (error) {
        Swal.fire({
          icon                : "error",
          titleText           : "Error!",
          text                : error.message,
          buttonsStyling      : false,
          confirmButtonClass  : "btn btn-brand",
        });
      }
    };
    if (!ignore) fetchBlogTagsAll();
    return () => {
      ignore = true;
    };
  }, [refresh]);


  
  useEffect(() => {
    if (!showAddBlogTag) setSelectedTag(null);
  }, [showAddBlogTag]);
  


  const editTagHandler = (blogTag) => {
    setSelectedTag(blogTag);
    setshowAddBlogTag(true);
  };

  const toggleBlogTagMasterStatus =  async (blogTag) => {
    let a = blogTag;
    if (a.Active === "Y") {
      a.Active = "N"
    } else {
      a.Active = "Y"
    }
    await updateBlogTagMaster(a);
  };

  const refetchBlogTagsMaster = () => setRefresh((r) => !r);

  useEffect(() => {
    props.title("Blog Tag Master", "menuBlogTagMaster");
  }, []);


  return (
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
          <div className="kt-portlet__head-label">
            <nav className="file-nav navbar navbar-top">
              <NavLink activeClassName="top-nav-active" to="/blog-tag-master">
                Tag Master
              </NavLink>
            </nav>
          </div>

          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <div className="kt-portlet__head-actions">
                <button
                  className="btn btn-brand btn-icon-sm text-white"
                  onClick={() => setshowAddBlogTag(true)}>
                  <i className="la la-plus"></i>
                  New Tag
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="kt-portlet__body kt-portlet__body--fit">
          {loading ? (
            <Loading />
          ) : (
            <TagTable
              allBlogTags={allBlogTags}
              editTagHandler={editTagHandler}
              toggleBlogTagMasterStatus={toggleBlogTagMasterStatus}

            />
          )}
        </div>
      </div>
      
      {showAddBlogTag && (
        <AddBlogTag
          show={showAddBlogTag}
          onDismissModal={() => setshowAddBlogTag(false)}
          selectedTag={selectedTag}
          refetchBlogTagsMaster={refetchBlogTagsMaster}

        />
      )}
    </div>
  );
};

export default Tagmaster;
