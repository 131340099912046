import React, { useState, useEffect }                                           from "react";
import $                                                                        from "jquery";
import * as helper                                                              from "../global/Helper";
import RightSideDetails                                                         from "../global/RightSideDetails";
import AddAuthor,{AddAuthorBacklink}                                            from "./AddAuthor";
import BacklinkTable                                                            from "./BacklinkTable";
import {updateAuthor,getAuthorBacklinks,addAuthorBacklink,updateAuthorBacklink} from "../api/blogAPI";
import Swal                                                                     from "sweetalert2";

import "jquery-form";
import "jquery-validation";
import "./AuthorTable.css";

const AuthorTable = (props) => {
  
  const [authors,                    setAuthors]                      =  useState(props.authors);
  const [selectedAuthorBacklinks,    setSelectedAuthorBacklinks]      =  useState([]);
  const [selectedAuthor,             setSelectedAuthor]               =  useState(null);
  const [selectedBacklink,           setSelectedBacklink]             =  useState(null);
  const [showEditAuthor,             setShowEditAuthor]               =  useState(false);
  const [showEditAuthorBacklink,     setShowEditAuthorBacklink]       =  useState(false);
  const [doRefresh,                  setDoRefresh]                    =  useState(false);
  const [RefreshBacklinkTable,       setRefreshBacklinkTable]         =  useState(false);
  const [showRemoveSearch, setshowRemoveSearch] = useState(false);

  useEffect(() => {
    let ignore = false;
    const fetchAuthorBacklinks = async()=>{
      try {
        // setLoading(true);
        if(selectedAuthor !== null){
          const response = await getAuthorBacklinks(selectedAuthor.ID);
           setSelectedAuthorBacklinks(response);
        }
        // setLoading(false);
      } catch (error) {
        Swal.fire({
          icon                : "error",
          titleText           : "Error!",
          text                : error.message,
          buttonsStyling      : false,
          confirmButtonClass  : "btn btn-brand",
        });
      }
    }
    if (!ignore) fetchAuthorBacklinks();
    return () => {
      ignore = true;
    };

  },[selectedAuthor,RefreshBacklinkTable])

  useEffect(() => {
    helper.SetScrollHeight();
  });

  const searchAuthorHandler = ({ target }) => {
    if(target.value === ""){
      setshowRemoveSearch(false);
      return setAuthors( props.authors );
    }

    setshowRemoveSearch(true);
    const matchedAuthors = props.authors.filter((a)=>{
      let matchString = `${ a.Name } ${ a.Designation } ${ a.Email }`;
      let matchFound  = 0;

      const searchArr =  target.value.split(' ');
      searchArr.forEach(term=>{
        matchFound += matchString.toLowerCase().includes(term.toLowerCase()) ? 1 : 0;
      });

      return matchFound === searchArr.length;
    });
    setAuthors( matchedAuthors );
  };

  const clearSearchHandler = () =>{ 
    $("#generalSearch").val("");
    setshowRemoveSearch(false);
    return setAuthors( props.authors );
  }
  

  const updateAuthorData = async ( author ) => {
    await updateAuthor( author );
    if( selectedAuthor ){
      setSelectedAuthor( author )
      setDoRefresh( true );
    }
  };


  const addAuthorBacklinkData = async ( backlink ) => {
    await addAuthorBacklink( backlink );
    setRefreshBacklinkTable( !RefreshBacklinkTable );
  };


  const updateAuthorBacklinkData = async ( backlink ) => {
    await updateAuthorBacklink( backlink );
    setRefreshBacklinkTable( !RefreshBacklinkTable )
  };
  
  const showEditBacklinkModal = (b) => {
    delete b.mainIndex;
    setSelectedBacklink(b)
    setShowEditAuthorBacklink( true );
  };

  const showAddBacklinkModal = () => {
    setSelectedBacklink( null )
    setShowEditAuthorBacklink( true )
  };

  const toggleAuthorStatus = (a) => {

    $('.kt-datatable__row').off()
    setSelectedAuthor(null);

    if(a.Active === "Y"){
      a.Active = "N";
    }else{
      a.Active = "Y";
    }
    updateAuthorData(a)
  };

  const closeDrawerHandler = () => {
    if (doRefresh) props.refetchAuthors();
    setTimeout(() => {
      setSelectedAuthor(null);
    }, 180);
  };

 

  return (
    <div className="kt-form kt-form--label-right kt-margin-b-10">
      <div className="row align-items-center stickyFilter">
        <div className="col-xl-12 order-2 order-xl-1">
          <div className="row align-items-center">
            <div className="col-md-4 kt-margin-b-20-tablet-and-mobile margin-b0">
              <div className="kt-input-icon kt-input-icon--left">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Author..."
                  id="generalSearch"
                  onChange={searchAuthorHandler}>
                </input>
                <span className="kt-input-icon__icon kt-input-icon__icon--left">
                  <span>
                    <i className="la la-search"></i>
                  </span>
                </span>
                {showRemoveSearch && (
                  <span className="kt-input-icon__icon kt-input-icon__icon--right">
                      <span onClick={clearSearchHandler}>
                          <i className="la la-close"></i>
                      </span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {authors.length > 0 ? (
        <div className="row">
          {selectedAuthor && (
            <RightSideDetails
              onCloseDrawer={closeDrawerHandler}
              show={!!selectedAuthor}
              title="Author Details"
              // changeSelectedAuthorhandler={changeSelectedAuthorhandler}
              >
              <div className="kt-portlet">
                <div className="kt-portlet__body">
                  <div className="kt-widget kt-widget--user-profile-3">
                    <div className="kt-widget__top">
                      <div className="kt-widget__pic kt-widget__pic--brand kt-font-boldest">
                        {selectedAuthor.Name.slice(0, 1)}
                      </div>
                      <div className="kt-widget__content">
                        <div className="kt-widget__head">
                          <span className="kt-widget__username">
                            {selectedAuthor.Name}
                          </span>
                          <div className="kt-widget__action">
                            <span className="dropdown">
                              <a
                                className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                data-toggle="dropdown"
                                aria-expanded="true">
                                <i className="flaticon-more-1"></i>
                              </a>
                              <div
                                className="dropdown-menu dropdown-menu-right"
                                x-placement="top-end">
                                <a
                                  className="dropdown-item"
                                  onClick={() => setShowEditAuthor(true)}>
                                  <i className="la la-edit"></i>
                                  Edit
                                </a>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div className="kt-widget__subhead padding-t0">
                          <span className="padding-r10">
                            <i className="flaticon2-calendar-3"></i>
                            {selectedAuthor.Designation && selectedAuthor.Designation.toLowerCase()}
                          </span>
                          <br></br>
                          <span className="padding-r10">
                            <i className="flaticon2-new-email"></i>
                            {selectedAuthor.Email}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="kt-portlet kt-portlet--mobile">
                <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                  <div className="kt-portlet__head-label">
                    <nav className="file-nav navbar navbar-top">
                      <a className="top-nav-active" href="#Members">
                        BackLinks
                      </a>
                    </nav>
                  </div>
                  <div className="kt-portlet__head-toolbar">
                    <div className="kt-portlet__head-wrapper">
                      <div className="kt-portlet__head-actions margin-l10">
                        <button className="btn btn-brand btn-icon-sm text-white" onClick={showAddBacklinkModal} >
                          <i className="la la-plus"></i>
                          Add 
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="kt-portlet__body kt-portlet__body--fit">
                  <BacklinkTable
                    authorbacklinks={selectedAuthorBacklinks}
                    selectedAuthor={selectedAuthor}
                    editBacklinkHandler={showEditBacklinkModal}
                    updateBacklinkStatus={updateAuthorBacklinkData}
                    // doRefreshHandler={setDoRefresh}
                  />
                </div>
              </div>
            </RightSideDetails>
          )}
          <div className="col-sm-12">
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
              <table className="kt-datatable__table">
                <thead
                  className="kt-datatable__head"
                  style={{ display: "block" }}>
                  <tr className="kt-datatable__row block-row">
                    <th 
                      className="kt-datatable__cell text-clip" 
                      width="30%">
                      <span>Author</span>
                    </th>
                    <th 
                      className="kt-datatable__cell text-clip" 
                      width="35%">
                      <span>Email</span>
                    </th>
                    <th
                      className="kt-datatable__cell--right kt-datatable__cell"
                      width="10%">
                      <span className="margin-r20">Active</span>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>

            <div
              className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable"
              onScroll={helper.setStickyHeader}>
              <table className="kt-datatable__table table-striped">
                <tbody
                  className="kt-datatable__body"
                  style={{ display: "block" }}>
                  {authors.map((a, i) => {
                    return (
                      <tr
                        key={i}
                        className="kt-datatable__row block-row clickable"
                        onClick={() => setSelectedAuthor(a)}>
                        <td
                          width="30%"
                          title={a.Name}
                          className="kt-datatable__cell text-clip">
                          <div className="kt-user-card-v2">
                            <div className="kt-user-card-v2__pic">
                              <div className="kt-badge kt-badge--xl kt-badge--success">
                                {a.Name.slice(0, 1)}
                              </div>
                            </div>
                            <div className="kt-user-card-v2__details text-clip">
                              <span className="kt-user-card-v2__name text-clip">
                                {a.Name}
                              </span>
                              {a.Designation && (
                                <span 
                                  className="kt-user-card-v2__desc text-clip" 
                                  title={a.Designation}>
                                  {a.Designation.toLowerCase()}
                                </span>
                              )}
                            </div>
                          </div>
                        </td>
                        <td
                          className="kt-datatable__cell text-clip"
                          width="35%"
                          title={a.Email}>
                          <span>{a.Email}</span>
                        </td>
                        <td
                          className="kt-datatable__cell--right kt-datatable__cell"
                          width="10%"
                          onClick={(e)=>e.stopPropagation()}>
                          <span className="kt-switch kt-switch--icon">
                            <label>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                  a.Active === "Y" ? "checked" : ""
                                }
                                onChange={() => {
                                  toggleAuthorStatus(a)
                                }}
                              />
                              <span></span>
                            </label>
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <span className="empty-table-message">No Authors Found</span>
      )}

      {showEditAuthor && (
        <AddAuthor
          show={showEditAuthor}
          selectedAuthor={selectedAuthor}
          updateAuthorData={updateAuthorData}
          // setDoRefresh={setDoRefresh}
          // setSelectedAuthor={setSelectedAuthor}
          onDismissModal={() => setShowEditAuthor(false)}     
        />
      )}
      {showEditAuthorBacklink && (
        <AddAuthorBacklink
          show={showEditAuthorBacklink}
          selectedBacklink={selectedBacklink}
          selectedAuthorID={selectedAuthor.ID}
          addAuthorBackLinkHandler={addAuthorBacklinkData}
          updateAuthorBacklinkHandler={updateAuthorBacklinkData}
          onDismissModal={() => setShowEditAuthorBacklink(false)}     
            
          // setDoRefresh={setDoRefresh}
        />
      )}
    </div>
  );
};

export default AuthorTable;
