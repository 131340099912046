import cookie from "react-cookies";
import {sessionSecret} from "../../env.json";
import {Encryptor} from "../../components/global/encryptor";

const {encrypt,decrypt} = Encryptor(sessionSecret);

export const getAuthState = form => {
    const userCookie = cookie.load('clubEZAdmin')
    if (!userCookie) return false
    const params = JSON.parse(decrypt(userCookie))
    if (form === 'bool') return !!params.token
    if (!!params[form]) return params[form]
    else return params
}

export const setAuthState = (authParams,isRemember )=> {
    if (!authParams) {
        cookie.remove('clubEZAdmin');
    } else {
        //Encrypt Data
        const encryptedData = encrypt(JSON.stringify(authParams));
        //FOR Cookie Age 30Days set maxAge:259200 
        let cookieOptions =!isRemember? {path:'/',secure:true}:{path:'/',maxAge:259200,secure:true};
        cookie.save('clubEZAdmin',encryptedData,cookieOptions);
    }
}

