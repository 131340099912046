import React, { useState, useEffect }                         from "react";
import { NavLink }                                            from "react-router-dom";
import $                                                      from "jquery";
import Loading                                                from "../global/Loading";
import ClientTable                                            from "./ClientTable";
import * as helper                                            from "../global/Helper";
import Swal                                                   from "sweetalert2";
import imageLogo                                              from '../../assets/images/ic_image.png'; 
import ImageCropper                                           from "../global/ImageCropper";
import {uploadFile}                                           from  "../api/fileAPI";
import {addClient, getClientAll, updateClient}                from "../api/homepageAPI";
import "jquery-form";
import "jquery-validation";
import "../../assets/scripts/bootstrap-modal";
import "../../assets/scripts/jquery-validation.init";

export const AddClients = (props) => {

  const { 
    show, 
    onDismissModal,
    selectedClient,
    refetchClients
  } = props;
  
  const [client,            setClientData]        = useState(selectedClient? selectedClient: { Active: "Y"});
  const [bannerImageBlob,   setBannerImageBlob]   = useState(null);
  const [showImageCropper,  setShowImageCropper]  = useState(false);

  //ClientDataHandler
  const ClientDataHandler = ( key, value ) => {
    setClientData((data) => {
      return { ...data, [key]: value };
    });
  };
  
  const submitClientHandler = async (e) => {
    e.preventDefault();
    var form = $("#add-member-form");
    form.validate().destroy();
    form.validate({
      rules: {
        Name: {
          required: true,
        },
        Url: {
          required: true,
        },
        Index: {
            required: true,
        },
      },
    });
    
    if (!form.valid()) {
      return;
    }
  
    //Submit ClientData
    try{
      
      let fileName ;
      if(bannerImageBlob) fileName = await uploadFile(bannerImageBlob,client.Name.replace(/\s/g, "-").toLowerCase());

      if(fileName) client.Logo = fileName;

      if(client.Logo){
        if(selectedClient){
          if(!bannerImageBlob && client.Logo) client.Logo = client.Logo.split("WebsiteImages/")[1];
          await updateClient(client);
          Swal.fire({
            icon                      : "success",
            titleText                 : "Client Updated Successfully",
            showConfirmButton         : false,
            timer                     : 1500,
            toast                     : true,
            position                  :'bottom-end'
          });
          $("#add-client-modal").modal("hide");
        }else{
          helper.StartProcessing($("#save-btn"));
          await addClient(client);
          Swal.fire({
              icon              :'success',
              titleText         :'Client Added Successfully',
              showConfirmButton : false,
              timer             : 1500,
            });
        }

        refetchClients();
        $("#add-client-modal").modal("hide");
      }else{
        Swal.fire({
          icon                : 'error',
          titleText           : 'Error!',
          text                :  "Please upload logo!",
          buttonsStyling      :  false,
          confirmButtonClass  : 'btn btn-brand',
        });
      }
    }catch(err){
      helper.StopProcessing($("#save-btn"));
      Swal.fire({
        icon                : 'error',
        titleText           : 'Error!',
        text                :  err.message,
        buttonsStyling      :  false,
        confirmButtonClass  : 'btn btn-brand',
      });
    }
  };
  
  //Modal Opener
  useEffect(() => {
    $("#add-client-modal").modal({
      backdrop: "static",
    });

    $("#add-client-modal").on("hidden.bs.modal", function () {
      $(".modal").hasClass("show") && $("body").addClass("modal-open");
      onDismissModal();
    });

    $("#add-client-modal").modal("toggle");
  }, [show]);


  const deleteImageHandler = () => {
    setBannerImageBlob(null);
    if(client.Logo){
      delete client.Logo;
      setClientData({...client});
    }
  }

  const showImageCropperHandler = () => { 
    client.Name 
    ? setShowImageCropper(true)
    : Swal.fire(
      { 
        icon: "warning", 
        titleText: "Please Enter Name"
      }
    ) 
  }

  return (
    <>
      <div
        className="modal fade"
        id="add-client-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true">    
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{`${selectedClient ? "Edit" : "Add"} Client`}{" "} </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <div className="client-container">
                <form id="add-member-form">
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="Name"
                          id="Name"
                          placeholder="Name"
                          value={client.Name || ""}
                          onChange={({ target }) => ClientDataHandler("Name",helper.titleCase(target.value))}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                            <button
                            type="button"
                            className="btn btn-secondary btn-sm form-control"
                            onClick={showImageCropperHandler}>
                            <i className="fa fa-upload mr-2"></i> 
                            {`${bannerImageBlob ||(selectedClient && selectedClient.Logo) ? "Update" : "Upload"} Logo (500 W x 250 H pixels)`}
                            </button>
                        </div>
                    </div>
                  </div>
                  {( bannerImageBlob || (selectedClient && selectedClient.Logo) ) &&
                      <div className="row">
                          <div className="col-12">
                              <div className="kt-notification margin-b30" style={{backgroundColor: '#f7f8fa'}}>
                                  <span className="kt-notification__item">
                                      <div className="kt-notification__item-icon margin-r10" >
                                          <img height="30px" width="30px" src={imageLogo} />
                                      </div>
                                      <div className="kt-notification__item-details" >
                                          <div className="kt-notification__item-title kt-font-bold">
                                              {bannerImageBlob?
                                                  <a href={URL.createObjectURL(bannerImageBlob)} target="_blank" rel="noopener noreferrer">
                                                  {`${bannerImageBlob.name}.${bannerImageBlob.type.split('/')[1]}`}
                                                  </a>
                                                  :
                                                  <a href={client.Logo} target="_blank" rel="noopener noreferrer">
                                                  {client.Logo.split("WebsiteImages/")[1]}
                                                  </a>
                                              }
                                          </div>
                                      </div>
                                      <button 
                                          type="button"                    
                                          onClick={()=>deleteImageHandler()}
                                          className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                          <i className="flaticon2-rubbish-bin-delete-button"></i>
                                      </button>
                                  </span>
                              </div>
                          </div>
                      </div>
                  }
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                      <input
                          type="text"
                          className="form-control"
                          name="Url"
                          id="Url"
                          placeholder="Url"
                          value={client.Url || ""}
                          onChange={({ target }) => ClientDataHandler("Url",helper.titleCase(target.value))}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 ">
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          name="Index"
                          id="Index"
                          placeholder="Index"
                          value={client.Index || ""}
                          onChange={({ target }) => ClientDataHandler("Index", helper.capitalize(target.value))}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2">
                      <div className="form-group">
                        <div className="d-flex align-item-center">
                          <span className="pt-2 mr-3">Active</span>
                          <span className="kt-switch kt-switch--icon">
                            <label>
                              <input
                                type="checkbox"
                                name=""
                                defaultChecked={
                                client.Active === "Y" ? "checked" : ""
                                }
                                onChange={() => {
                                client.Active === "Y"
                                ? ClientDataHandler("Active", "N")
                                : ClientDataHandler("Active", "Y");
                                }}
                              />
                              <span>{client.Active}</span>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                </form>
              </div>
                  </div>
                  <div className="modal-footer">
                      <button
                          type="button"
                          className="btn btn-secondary margin-0 margin-r5"
                          data-dismiss="modal"
                          >        
                          Close
                      </button>
                      <button
                          id="update-btn"
                          onClick={submitClientHandler}
                          type="button"
                          className="btn btn-brand margin-0"
                          >       
                          { selectedClient  ? "Update" : "Save" }
                      </button>
                  </div>
              </div>
          </div>
      </div>
      {showImageCropper && (
        <ImageCropper
          show={showImageCropper}
          onDismissModal={() => setShowImageCropper(false)}
          fileAlias = {`${client.Name && client.Name.replace(/\s/g, "-").toLowerCase()}${Date.now()}`}
          imageRatio={{width:500, height:250}}
          DataHandler={(value)=>setBannerImageBlob(value)}
        />
      )}
    </>
  );
};


const Clients = (props) => {

    const [loading,                 setLoading]               =  useState(false);
    const [clientsData,             setClientsData]           =  useState([]);
    const [selectedClient,          setselectedClient]        =  useState(null);
    const [showAddClient,           setShowAddClient]         =  useState(false);
    const [refresh,                 setRefresh]               =  useState(false);

    
    useEffect(() => {
        if (!showAddClient) setselectedClient(null);
      }, [showAddClient]);
  
  
      useEffect(() => {
        let ignore = false;
        const fetchClientDataAll = async () => {
          try {
            setLoading(true);
            const response = await getClientAll();
            setClientsData(response);
            setLoading(false);
          } catch (error) {
            Swal.fire({
              icon                  : "error",
              titleText             : "Error!",
              text                  : error.message,
              buttonsStyling        : false,
              confirmButtonClass    : "btn btn-brand",
            });
          }
        };
        if (!ignore) fetchClientDataAll();
        return () => {
          ignore = true;
        };
      }, [refresh]);


    const editClientHandler = (client) =>{
        setselectedClient(client);
        setShowAddClient(true);
      }
  
      const toggleClientSwitch =  async (client) => {
        let a = client;
        if (a.Active === "Y") {
          a.Active = "N"
        } else {
          a.Active = "Y"
        }
        if(a.Logo) a.Logo = a.Logo.split("WebsiteImages/")[1];
        await updateClient(a);
      }

   
      const refetchClients = () => setRefresh((r) => !r);

      useEffect(() => { 
        props.title("Clients", "menuClients");
      }, []);


    return(
        <>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
            <div className="kt-portlet kt-portlet--mobile">
                <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                    <div className="kt-portlet__head-label">
                        <nav className="file-nav navbar navbar-top">
                            <NavLink activeClassName="top-nav-active" to="/clients">
                            All Clients
                            </NavLink>
                        </nav>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                        <div className="kt-portlet__head-wrapper">
                            <div className="kt-portlet__head-actions">
                                <button
                                    className="btn btn-brand btn-icon-sm text-white"
                                    onClick={() =>setShowAddClient(true)}>
                                    <i className="la la-plus"></i>
                                    New Client
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kt-portlet__body kt-portlet__body--fit">
                    {loading ? 
                    (<Loading />) : 
                    (<ClientTable
                        clientsData={clientsData}
                        editClientHandler={editClientHandler}
                        toggleClientSwitch={toggleClientSwitch}
                    />)
                    }
                </div>
            </div>  
        </div>
        
      {showAddClient && 
        (
            <AddClients
                show={showAddClient}
                onDismissModal={()=>setShowAddClient(false)}
                selectedClient={selectedClient}
                refetchClients={refetchClients}
            />
        )
      }   
    </>
  )
}


export default Clients;