import { axiosPost }      from "./axiosFunctions";
import {checkLoginToken}  from "./common";

const uploadFileUrl = '/api/file/uploadFile';

export const uploadFile = async(file,fileAlias)=>{
  const token = checkLoginToken();
  if (!token) return;
  if(!fileAlias) throw new Error('Enter Alias');
  const formData = new FormData();
  formData.append('file',file)
   const response = await axiosPost(
    `${uploadFileUrl}/${fileAlias}`,
    formData,
    { 
        headers: { auth_token: `bearer ${token}` },
        onUploadProgress(progress) {
            console.log('file upload progress:', `${progress.loaded}/${progress.total}`)
        }
    }
  );
  return response;
}

