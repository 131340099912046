import React, { useState, useEffect } from "react";
import { NavLink }                    from "react-router-dom";
import imageLogo                      from '../../assets/images/ic_image.png';
import Loading                        from "../global/Loading";
import BlogTable                      from "./BlogTable";
import ImageCropper                   from "../global/ImageCropper";
import * as helper                    from "../global/Helper";
import moment                         from "moment";
import Swal                           from "sweetalert2";
import Select                         from "react-select";
import $                              from "jquery";
import "jquery-form";
import "jquery-validation";
import "../../assets/scripts/bootstrap-modal";
import "../../assets/scripts/jquery-validation.init";
import {getAuthors,getBlogTagMasterAll,addBlog,getBlogs,getBlogTags,updateBlog,checkIfBlogExists} from "../api/blogAPI";
import {uploadFile} from  "../api/fileAPI";


//Add Blog Modal Starts ---->
export const AddBlog = (props) => {

  const {
    show,
    onDismissModal,
    selectedBlog,
    setSelectedBlog,
    refetchBlogs
  } = props;
  
  const [btnLoading,             setBtnLoading]         = useState(false);
  const [showImageCropper,       setShowImageCropper]   = useState(false);
  const [authorList,             setAuthorList]         = useState([]);
  const [blogTagList,            setBlogTagList]        = useState([]);
  const [bannerImageBlob,        setBannerImageBlob]    = useState(null);
  const [isTagEdited,            setisTagEdited]        = useState(false);
  const [BlogData,               setBlogData]           = useState(selectedBlog?selectedBlog:{ Active: "Y",});
  const [selectedBlogTags,       setselectedBlogTags]   = useState([]);
    

  //Fetch Essential Data (AUTHORS / BLOG TAGS)
  useEffect(() => {
    let ignore = false;
    const fetchEssentialData = async () => {
      try {
        const authorResponse  = await getAuthors();
        const blogTagResponse = await getBlogTagMasterAll();
        if(authorResponse) {
          authorResponse.map( a => a.Active === "Y" && setAuthorList( data => [...data, { label : a.Name, value : a } ]))
        }
        if(blogTagResponse){
          blogTagResponse.map(b => b.Active === "Y" && setBlogTagList( data => [...data, {label : b.TagName, value :b.ID }]))
        }
      } catch (error) {
        Swal.fire({
          icon                : "error",
          titleText           : "Error!",
          text                : error.message,
          buttonsStyling      : false,
          confirmButtonClass  : "btn btn-brand",
        });
      }
    }
    if(!ignore) fetchEssentialData()
      return () => {
      ignore = true;
      }
  }, [])


  //FETCH SELECTED BLOGTAGS
  useEffect(() => {
    let ignore = false;
    const fetchSelectedBlogTags = async () => {
      try {
        if(selectedBlog.ID){ 
          const response = await getBlogTags( selectedBlog.ID );
          if(response.length > 0){
            response.map(t => setselectedBlogTags(d=> [...d, { label:t.TagName, value:t.TagID }]))
          }
        }
      } catch (error) {
        Swal.fire({
          icon                          : "error",
          titleText                     : "Error!",
          text                          : error.message,
          buttonsStyling                : false,
          confirmButtonClass            : "btn btn-brand",
        });
      }
    }
    if(!ignore && selectedBlog)fetchSelectedBlogTags()
    return()=>{
      ignore = true;
    }
  }, [selectedBlog]);

  useEffect(() => {
    if(BlogData.Content) window.setEditorValue("#editorContent",BlogData.Content);
    if (BlogData.AuthorID) $("#Author-error").hide();
    if (BlogData.Title){$("#Alias-error").hide()}else{$("#Alias-error").show()}
  }, [BlogData]);
      

  //BlogDataHandler
  const BlogDataHandler = (key, value) => {
    setBlogData((data) => {
      return { ...data, [key]: value };
    });
  };

  const addBlogHandler = async (e) => {
    e.preventDefault();
    //get ckEditorData
    const Content = window.getEditorValue("#editorContent");
    var form = $("#add-blog-form");
    form.validate().destroy();

    form.validate({
      rules: {
        Title: {
          required: true,
        },
        Alias: {
          required: true,
        },
        Description: {
          required: true,
        },
        BlogDate: {
          required: true,
        },
        Index: {
          required: true,
        },
        Author: {
          required: true,
        },
      },
    });

    if (!form.valid()) {
      return;
    }

    if (!Content) {
      return Swal.fire({
        icon  : "error",
        title : "Blog Content is required !",
      });
    }

    // Blog data save code goes here
    try {
      if(!selectedBlog || (selectedBlog && selectedBlog.Alias !== BlogData.Alias)){
        const response = await checkIfBlogExists(BlogData.Alias);
        if (response) {
          return Swal.fire({
            icon                      : "warning",
            titleText                 : " Blog Already exists !",
            buttonsStyling            : false,
            confirmButtonClass        : "btn btn-brand",
          })
        }
      }   
   
      helper.StartProcessing($("#save-btn"));
      let fileName ;
      let blogTags =[];
      // upload File api -> requires BlobFile,FileAlias
      if(bannerImageBlob) fileName = await uploadFile( bannerImageBlob, BlogData.Alias );
      // Add Blog fileName
      if(fileName) BlogData.BannerImage = fileName;

      blogTags = selectedBlogTags.map(tag => tag.value);
      
      if(selectedBlog){

        delete BlogData.Name;
        if(!bannerImageBlob && BlogData.BannerImage) BlogData.BannerImage = BlogData.BannerImage.split("WebsiteImages/")[1];
    
        updateBlog({...BlogData,Content},blogTags,isTagEdited).then(()=>{
          Swal.fire({
            icon                  : 'success',
            titleText             : 'Blog Updated Successfully',
            showConfirmButton     :  false,
            timer                 :  1500,
            toast                 :  true,
            position              : 'bottom-end'
          });
        }) 
      }else{
        // Add Blog API
        await addBlog({ ...BlogData, Content }, blogTags).then(()=>{
          Swal.fire({
            icon                : 'success',
            titleText           : 'Blog Added Successfully',
            showConfirmButton   :  false,
            timer               :  1500,
          });
        }) 
      }
      refetchBlogs()
      $("#add-blog-modal").modal("hide");
    } catch (err) {
      helper.StopProcessing($("#save-btn"));
      Swal.fire({
        icon                : 'error',
        titleText           : 'Error!',
        text                : err.message,
        buttonsStyling      : false,
        confirmButtonClass  : 'btn btn-brand',
      });
    }
    
  };

  const showDatePickerHandler = ( target, show ) => {
    if (show) {
      target.type = "date";
    } else {
      if (!target.value) {
        target.type = "text";
      }
    }
  };


  //Modal Opener
  useEffect(() => {
    $("#add-blog-modal").modal({
      backdrop: "static",
    });

    window.initCkeditor("#editorContent");

    $("#add-blog-modal").on("hidden.bs.modal", function () {
      $(".modal").hasClass("show") && $("body").addClass("modal-open");
      window.removeCkeditor("editorContent");
      onDismissModal();
      setSelectedBlog(null);
    });
    $("#add-blog-modal").modal("toggle");
  }, [show]);

  const deleteImageHandler = () => {
    setBannerImageBlob(null);
    if(BlogData.BannerImage){
      delete BlogData.BannerImage;
      setBlogData({...BlogData});
    }
  }

  const showImageCropperHandler = () => { 
    BlogData.Alias
    ? setShowImageCropper(true)
    : Swal.fire(
      { 
        icon: "warning", 
        titleText: "Please Enter Alias"
      }
    ) 
  }


  return (
    <>
      <div
        className="modal fade"
        id="add-blog-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{`${selectedBlog?"Edit":"Add"} Blog`}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                disabled={btnLoading}>
              </button>
            </div>
            <div className="modal-body">
              <div className="author-container">
                <form id="add-blog-form">
                  <div className="row">
                    <div className="col-lg-12">
                      {/* Name / Alias */}
                      <div className="row">
                        <div className="col-lg-6 col-md-12">
                          <input
                            type="text"
                            className="form-control"
                            name="Title"
                            id="Title"
                            placeholder="Title"
                            value={BlogData.Title || ""}
                            onChange={({ target }) => {
                              BlogDataHandler("Title", helper.titleCase(target.value));

                              BlogDataHandler(
                                "Alias",
                                target.value.replace(/\s/g, "-").toLowerCase()
                              );
                            }}
                          />
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <input
                            type="text"
                            className="form-control"
                            name="Alias"
                            id="Alias"
                            placeholder="Alias"
                            value={BlogData.Alias || ""}
                            onChange={({ target }) =>
                              BlogDataHandler(
                                "Alias",
                                target.value.replace(/\s/g, "-").toLowerCase()
                              )
                            }
                          />
                        </div>
                      </div>
                      {/* Description */}
                      <div className="row margin-t20">
                        <div className="col-12">
                          <textarea
                            className="form-control"
                            name="Description"
                            id="Description"
                            row="2"
                            placeholder="Description"
                            value={BlogData.Description || ""}
                            onChange={({ target }) =>
                              BlogDataHandler("Description",helper.capitalize(target.value))
                            }
                          />
                        </div>
                      </div>

                      {/* Author / Banner Image */}
                      <div className="row margin-t10">
                        <div className="col-lg-6 col-md-12 pt-2  padding-t10">
                          <Select
                            options={authorList}
                            value={BlogData.AuthorID && authorList.find(a => a.value.ID === BlogData.AuthorID)}
                            placeholder="Select Author"
                            onChange={(selectedAuthor) => {
                              if (selectedAuthor)
                                BlogDataHandler(
                                  "AuthorID",
                                  selectedAuthor.value.ID
                                );
                            }}
                            id="Author"
                            name="Author"
                            className="Author"
                          />
                        </div>

                        <div className="col-lg-6 col-md-12 py-3">
                          <button
                            type="button"
                            className="btn btn-secondary btn-sm form-control"
                            onClick={showImageCropperHandler}>
                            <i className="fa fa-upload mr-2"></i> 
                            {`${bannerImageBlob ||(selectedBlog && selectedBlog.BannerImage) ? "Update" : "Upload"} Banner Image (1000 W x 500 H pixels)`}
                          </button>
                        </div>
                      </div>
                               
                      {( bannerImageBlob || (selectedBlog && selectedBlog.BannerImage) ) &&
                        <div className="row">
                          <div className="col-12">
                            <div className="kt-notification margin-b30" style={{backgroundColor: '#f7f8fa'}}>
                              <span className="kt-notification__item">
                                  <div className="kt-notification__item-icon margin-r10" >
                                      <img height="30px" width="30px" src={imageLogo}  />
                                  </div>
                                  <div className="kt-notification__item-details" >
                                      <div className="kt-notification__item-title kt-font-bold">
                                        {bannerImageBlob?
                                          <a href={URL.createObjectURL(bannerImageBlob)} target="_blank" rel="noopener noreferrer">
                                          {`${bannerImageBlob.name}.${bannerImageBlob.type.split('/')[1]}`}
                                          </a>
                                         :
                                         <a href={BlogData.BannerImage} target="_blank" rel="noopener noreferrer">
                                          {  BlogData.BannerImage.split("WebsiteImages/")[1]}
                                         </a>
                                        }
                                      </div>
                                  </div>
                                  <button 
                                      type="button"                    
                                      onClick={()=>deleteImageHandler()}
                                      className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                      <i className="flaticon2-rubbish-bin-delete-button"></i>
                                  </button>
                              </span>
                            </div>
                          </div>
                        </div>
                      }
               
                      {/* Content- CkEditor */}
                      <div className="row margin-t20">
                        <div className="col-12">
                          <textarea name="editorContent" id="editorContent" />
                        </div>
                      </div>

                      {/* Date Index Active */}
                      <div className="row margin-t10">
                        <div className="col-lg-6 col-md-4">
                          <input
                            className="form-control"
                            type="text"
                            id="BlogDate"
                            name="BlogDate"
                            placeholder="Date"
                            value={BlogData.Date && moment(BlogData.Date).format('YYYY-MM-DD')}
                            min={moment().format("YYYY-MM-DD")}
                            onFocus={(event) =>
                              showDatePickerHandler(event.target, true)
                            }
                            onBlur={(event) =>
                              showDatePickerHandler(event.target, false)
                            }
                            onChange={({ target }) =>
                              BlogDataHandler("Date", target.value)
                            }
                          />
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <input
                            type="number"
                            className="form-control"
                            name="Index"
                            id="Index"
                            placeholder="Index"
                            value={BlogData.Index || ""}
                            onChange={({ target }) =>
                              BlogDataHandler("Index", parseInt(target.value))
                            }
                          />
                        </div>
                        <div className="col-lg-2 col-md-4 pt-3">
                          <div className="d-flex align-item-center">
                            <span className="pt-2 mr-3">Active</span>
                            <span className="kt-switch kt-switch--icon">
                              <label>
                                <input
                                  type="checkbox"
                                  name="Active"
                                  defaultChecked={
                                    BlogData.Active === "Y" ? "checked" : ""
                                  }
                                  onChange={() => {
                                    BlogData.Active === "N"
                                      ? BlogDataHandler("Active", "Y")
                                      : BlogDataHandler("Active", "N");
                                  }}
                                />
                                <span> </span>
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>

                      {/*Meta Title*/}
                      <div className="row ">
                        <div className="col-12">
                          <textarea
                            id="MetaTitle"
                            className="form-control"
                            name="MetaTitle"
                            row="2"
                            value={BlogData.MetaTitle}
                            placeholder="Meta Title"
                            onChange={({ target }) =>
                              BlogDataHandler("MetaTitle", target.value)
                            }
                          />
                        </div>
                      </div>
                      {/*Meta Keyword*/}
                      <div className="row margin-t20">
                        <div className="col-12">
                          <textarea
                            row="2"
                            id="MetaKeyword"
                            className="form-control"
                            name="MetaKeyword"
                            value={BlogData.MetaKeyword}
                            placeholder="Meta Keyword"
                            onChange={({ target }) =>
                              BlogDataHandler("MetaKeyword", target.value)
                            }
                          />
                        </div>
                      </div>
                      {/*Meta Description*/}
                      <div className="row margin-t20">
                        <div className="col-12">
                          <textarea
                            row="2"
                            id="MetaDescription"
                            className="form-control"
                            name="MetaDescription"
                            placeholder="Meta Description"
                            value={BlogData.MetaDescription}
                            onChange={({ target }) =>
                              BlogDataHandler("MetaDescription", target.value)
                            }
                          />
                        </div>
                      </div>

                      {/*BLOG TAGS*/}
                      <div className="row margin-t20 ">
                        <div className="col">
                          <Select
                            options={blogTagList}
                            isMulti
                            placeholder="Select Blog Tags"
                            value={selectedBlogTags}
                            onChange={(selected) => {
                              if (selected !== null) {
                                const tags = selected.map(tag => tag);
                                setselectedBlogTags(tags);
                                if(selectedBlog) setisTagEdited(true);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary margin-0 margin-r5"
                data-dismiss="modal"
                disabled={btnLoading}>
                Close
              </button>

              <button
                id="save-btn"
                onClick={addBlogHandler}
                type="button"
                className="btn btn-brand margin-0"
                disabled={btnLoading}>
               {`${selectedBlog?"Update":"Save"} `}
              </button>
            </div>
          </div>
        </div>
      </div>

      {showImageCropper && (
        <ImageCropper
          show={showImageCropper}
          onDismissModal={() => setShowImageCropper(false)}
          fileAlias ={BlogData.Alias}
          DataHandler={(value)=>setBannerImageBlob(value)}
          onPage="knowledgebase"
          imageRatio={{width:1000, height:500}}
        />
      )}
    </>
  );
};

//Add Blog Modal Ends ---->

// Main Blog Component
const Blog = (props) => {

  const [AllBlogs,          setAllBlog]      = useState([]);
  const [loading,           setLoading]      = useState(false);
  const [refresh,           setRefresh]      = useState(false);
  const [showAddBlog,       setshowAddBlog]  = useState(false);
  const [selectedBlog,      setSelectedBlog] = useState(null);


  const EditBlogHandler=(blog)=>{
    setSelectedBlog(blog);
    setshowAddBlog(true);
  };

  const toggleBlogStatus = async (blogData) => {
    try {
      await updateBlog(blogData,[],false);
      refetchBlogs();
    } catch (err) {
      Swal.fire({
        icon                  : "error",
        titleText             : "Error!",
        text                  : err.message,
        buttonsStyling        : false,
        confirmButtonClass    : "btn btn-brand",
      });
    }

  }

  useEffect(() => {
    let ignore = false;
    const fetchBlogs = async () => {
      try {
        setLoading(true);
        const response = await getBlogs();
        if(response.length > 0) setAllBlog(response);
        setLoading(false);
      } catch (error) {
        Swal.fire({
          icon                  : "error",
          titleText             : "Error!",
          text                  : error.message,
          buttonsStyling        : false,
          confirmButtonClass    : "btn btn-brand",
        });
      }
    }
    if (!ignore) fetchBlogs();
    return () => {
      ignore = true;
    };
  },[refresh])

  useEffect(() => {
    props.title("Blog", "menuBlog");
  }, []);  

  const refetchBlogs = () => setRefresh((r) => !r);
  
  return (
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
          <div className="kt-portlet__head-label">
            <nav className="file-nav navbar navbar-top">
              <NavLink activeClassName="top-nav-active" to="/blog">
                Blog
              </NavLink>
            </nav>
          </div>

          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <div className="kt-portlet__head-actions">
                <button
                  className="btn btn-brand btn-icon-sm text-white"
                  onClick={() => setshowAddBlog(true)}>
                  <i className="la la-plus"></i>
                  New Blog
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="kt-portlet__body kt-portlet__body--fit">
          {loading ? (
            <Loading />
          ) : (
            <BlogTable 
              AllBlogs={AllBlogs}
              EditBlogHandler={EditBlogHandler}
              toggleBlogStatus={toggleBlogStatus}
              refetchBlogs={() => refetchBlogs()}
            />
          )}
        </div>
      </div>

      {showAddBlog && (
        <AddBlog
          show={showAddBlog}
          refetchBlogs={refetchBlogs}
          selectedBlog={selectedBlog}
          setSelectedBlog={(v)=>setSelectedBlog(v)}
          onDismissModal={() => setshowAddBlog(false)}
        />
      )}
    </div>
  );
};

export default Blog;
